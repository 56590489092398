<template>
  <div
    v-if="benefitsData"
    class="w-100 work-benefits-container container p-0 mt-5"
  >
    <ul class="list-unstyled row mb-0">
      <li
        v-for="(item, benefitsIndex) in benefitsData"
        :key="benefitsIndex"
        class="benefits__item d-flex col-6 col-sm-4"
        data-bs-toggle="modal"
        data-bs-target="#benefits__item__modal"
        @click="getItemIndex(benefitsIndex)"
      >
        <div class="d-flex w-100 h-100">
          <div class="w-100 h-100 px-3">
            <div
              class="d-flex flex-column w-100 h-100 justify-content-center align-items-center"
            >
              <div
                v-if="iconsLoading && benefitsIcons"
                class="placeholder-container mb-2 mb-md-4"
              ></div>
              <img
                v-else
                class="mb-2 mb-md-4"
                :src="iconsData[benefitsIndex]"
                :alt="benefitsIcons[benefitsIndex].careersImageAltText"
              />
              <p class="text-decoration-none text-center mb-0">
                {{ item.careersGenericTitle }}
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div
      class="modal fade"
      id="benefits__item__modal"
      tabindex="-1"
      aria-labelledby="benefits__item__modal__label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div class="header-spacer" style="width: 32px"></div>
            <div class="d-flex flex-column align-items-center">
              <img
                v-if="!iconsLoading"
                class="my-4"
                :src="iconsData[modalItemIndex]"
                :alt="benefitsIcons[modalItemIndex].careersImageAltText"
              />
              <h1
                v-if="benefitsData[modalItemIndex]"
                class="modal-title fs-5"
                id="benefits__item__modal__label text-center"
              >
                {{ benefitsData[modalItemIndex].careersGenericTitle }}
              </h1>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            v-if="benefitsData[modalItemIndex]"
            v-html="benefitsData[modalItemIndex].careersGenericText"
            class="modal-body pb-4"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Work-Benefits",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {
      iconsLoading: true,
      iconsData: {},
      modalItemIndex: 0,
    };
  },
  computed: {
    benefitsData() {
      return this.contentElements(this.contentfulComponents.benefitsContent);
    },
    benefitsIcons() {
      return this.contentIcons(this.contentfulComponents.benefitsIcons);
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
    contentIcons(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.careersImageIndex) - parseFloat(b.careersImageIndex)
        );
        return contentData;
      }
      return null;
    },
    async fetchIcons(data) {
      let icons = {};
      for (let index = 0; index < data.length; index++) {
        const iconUrl = await this.getImageData(
          data[index].careersImage.sys.id
        );
        icons[index] = iconUrl;
      }

      this.iconsData = icons;
    },
    getItemIndex(index) {
      this.modalItemIndex = index;
    },
  },
  created() {
    this.getContentful("16hTOXWcI8wBHsp8NC7Zau");
  },
  async beforeUpdate() {
    if (this.iconsLoading) {
      await this.fetchIcons(this.benefitsIcons);
    }
    setTimeout(() => {
      this.iconsLoading = false;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.work-benefits-container {
  max-width: 1000px !important;

  .benefits__item {
    cursor: pointer;

    &:nth-child(1),
    &:nth-child(2) {
      border-top: 1px solid map-get($colors, geyser);
    }

    @include media-breakpoint-only(xs) {
      &:nth-child(odd) {
        border-bottom: 1px solid map-get($colors, geyser);
        border-left: 1px solid map-get($colors, geyser);
        border-right: 1px solid map-get($colors, geyser);
      }

      &:nth-child(even) {
        border-bottom: 1px solid map-get($colors, geyser);
        border-right: 1px solid map-get($colors, geyser);
      }
    }

    &::before {
      content: "";
      padding: 50% 0;
    }

    @include media-breakpoint-up(sm) {
      &:nth-child(3) {
        border-top: 1px solid map-get($colors, geyser);
      }

      &:nth-child(3n + 1) {
        border-bottom: 1px solid map-get($colors, geyser);
        border-left: 1px solid map-get($colors, geyser);
        border-right: 1px solid map-get($colors, geyser);
      }

      &:nth-child(3n),
      &:nth-child(3n + 2) {
        border-bottom: 1px solid map-get($colors, geyser);
        border-right: 1px solid map-get($colors, geyser);
      }
    }

    .placeholder-container {
      width: 35px;
      height: 35px;
      background-color: map-get($colors, mercury);
    }
  }

  #benefits__item__modal {
    .modal-dialog {
      max-width: 670px;

      .modal-content {
        .modal-header {
          border-bottom: none;
          align-items: flex-start;

          img {
            width: fit-content;
          }

          .btn-close {
            margin: -0.5rem -0.5rem -0.5rem 0;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .modal-body {
          :deep(ul) {
            list-style-type: none;

            li {
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }
  }
}
</style>
