<template>
  <div class="container px-3 px-md-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="more-about-us-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>
        <SubNavigation :subNavId="'19vZME3aMm32S5cE9duVlS'" />
        <div class="mt-2 mt-md-4 px-0 px-sm-3 tab-content">
          <div
            class="tab-pane fade show active"
            id="our-values"
            role="tabpanel"
            aria-labelledby="our-values-tab"
          >
            <OurValues />
          </div>
          <div
            class="tab-pane fade"
            id="the-regatta-foundation"
            role="tabpanel"
            aria-labelledby="the-regatta-foundation-tab"
          >
            <TheRegattaFoundation />
          </div>
          <div
            class="tab-pane fade"
            id="sustainability"
            role="tabpanel"
            aria-labelledby="sustainability-tab"
          >
            <SustainabilityAtRegatta />
          </div>
          <div
            class="tab-pane fade"
            id="history"
            role="tabpanel"
            aria-labelledby="history-tab"
          >
            <OurHistory />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";
import SubNavigation from "../components/Navigation/SubNavigation";
import TheRegattaFoundation from "../components/AboutUs/TheRegattaFoundation";
import OurValues from "../components/AboutUs/OurValues";
import SustainabilityAtRegatta from "../components/AboutUs/SustainabilityAtRegatta";
import OurHistory from "../components/AboutUs/OurHistory";

export default {
  name: "About_Us",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
    SubNavigation,
    TheRegattaFoundation,
    OurValues,
    SustainabilityAtRegatta,
    OurHistory,
  },
  created() {
    this.initContentful("2HwSn9Q6JhRmdm0H9OytwE"); // update to the entry ID for that page
    this.dataItem = "careersAboutUs"; // to use within the init response to get other entries for that page
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../css/partials/bs-overwrites/colors";

.tab-content {
  > .tab-pane {
    display: block !important;

    &.active {
      opacity: 1;
    }

    &.fade {
      &:not(.show) {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
}
</style>
