<template>
  <div class="location-wrapper d-flex flex-wrap">
    <section class="location-left-content col-12 col-md-6 pe-0 pe-md-3 pe-lg-4">
      <div
        v-if="contentfulComponents.careersLocationPageVideoUrl"
        style="padding: 56.25% 0 0 0; position: relative"
        class="mb-4"
      >
        <iframe
          :src="
            contentfulComponents.careersLocationPageVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          class="video-content"
          :title="contentfulComponents.careersLocationPageVideoTitle"
        ></iframe>
      </div>
      <div
        class="mb-4"
        v-for="(element, leftContentIndex) in locationContentLeftElements"
        :key="leftContentIndex"
      >
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
    </section>

    <section
      class="location-right-content col-12 col-md-6 ps-0 pe-0 ps-md-3 ps-lg-4"
    >
      <div
        class="mb-4"
        v-for="(element, rightContentIndex) in locationContentRightElements"
        :key="rightContentIndex"
      >
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
      <div class="location-right-content__map-container">
        <div
          v-if="
            contentfulComponents.careersLocationPageContentId === 'head-office'
          "
          class="d-flex flex-column"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4326.016530540818!2d-2.3386108371321663!3d53.46517489411759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bae99e84a66b7%3A0xf765e9bd9cd5a30d!2sRegatta!5e0!3m2!1sen!2sie!4v1668009779511!5m2!1sen!2sie"
            max-width="800"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          >
          </iframe>
          <div class="my-2">
            <a
              href="https://goo.gl/maps/Hfb29ffUmJvGZcdu8"
              class="btn rounded-pill py-2 px-5 mt-3 map-content__cta"
              target="_blank"
              :class="contentfulComponents.careersLocationPageButtonClass"
              >{{ contentfulComponents.careersLocationPageButtonText }}</a
            >
          </div>
        </div>
        <div
          v-else-if="
            contentfulComponents.careersLocationPageContentId === 'warehouse'
          "
          class="d-flex flex-column"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.7087393862375!2d-2.9197037422766416!3d53.29475230731958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ade4394c6c4a1%3A0x4ced4897c96e0f3c!2sRegatta%20Distribution%20Centre%20-%20Not%20a%20shop!5e0!3m2!1sen!2sie!4v1668070262854!5m2!1sen!2sie"
            max-width="800"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          >
          </iframe>
          <div class="my-2">
            <a
              href="https://goo.gl/maps/PRoodUyPBQYmWHL56"
              class="btn rounded-pill py-2 px-5 mt-3 map-content__cta"
              target="_blank"
              :class="contentfulComponents.careersLocationPageButtonClass"
              >{{ contentfulComponents.careersLocationPageButtonText }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <h2
      v-if="contentfulComponents.careersLocationPageBottomSectionHeader"
      class="h3 w-100 mt-4 py-3 border-top border-bottom text-center"
    >
      {{ contentfulComponents.careersLocationPageBottomSectionHeader }}
    </h2>
    <BenefitsComponent
      v-if="benefitsData"
      :benefitsData="benefitsData"
      :contentId="contentfulComponents.careersLocationPageContentId"
      class="container mt-4"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
import BenefitsComponent from "../Commons/BenefitsComponent";

export default {
  name: "Location-Page",
  mixins: [ContentfulCMS],
  components: {
    BenefitsComponent,
  },
  data() {
    return {};
  },
  props: {
    locationId: {
      type: String,
      required: false,
    },
  },
  computed: {
    locationContentLeftElements() {
      return this.contentElements(
        this.contentfulComponents.careersLocationPageContentLeft
      );
    },
    locationContentRightElements() {
      return this.contentElements(
        this.contentfulComponents.careersLocationPageContentRight
      );
    },
    benefitsData() {
      if (this.contentfulComponents.careersLocationPageBottomSectionContent) {
        let contentData =
          this.contentfulComponents.careersLocationPageBottomSectionContent;
        contentData.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        return contentData;
      }
      return null;
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful(this.locationId);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.location-wrapper {
  h2 {
    font-weight: 400;
  }

  .location-left-content {
    border-right: 0;

    @include media-breakpoint-up(md) {
      border-right: 1px solid map-get($colors, geyser);
    }

    .video-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .map-content__cta {
    &.secondary {
      background-color: map-get($colors, white) !important;
      color: map-get($colors, black) !important;
    }

    &.primary {
      background-color: map-get($colors, black) !important;
      color: map-get($colors, white) !important;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-block: 0.75rem !important;
    }
  }
}
</style>
