import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage";
import WorkWithUs from "../views/WorkWithUs";
import OurBrands from "../views/OurBrands";
import WhereWeAre from "../views/WhereWeAre";
import AllVacancies from "../views/AllVacancies";
import SingleVacancies from "../views/SingleVacancy";
import ApplicantPrivacyNotice from "../views/ApplicantPrivacyNotice";
import CookiePolicy from "../views/CookiePolicy";
import GenderPayGap from "../views/GenderPayGap";
import ModernSlaveryStatement from "../views/ModernSlaveryStatement";
import DepartmentComponent from "../components/Commons/DepartmentComponent";
import EmployeeProfile from "../components/Commons/EmployeeProfile";
import AboutUs from "../views/MoreAboutUs";
import PageNotFound from "../views/PageNotFound";

Vue.use(VueRouter);

const overarchingMeta = {
  meta: {
    title: "Regatta Group Careers",
    description:
      "Welcome to the Regatta Group Careers Site - home to exciting career opportunities for our outdoor brands – Regatta Great Outdoors, Craghoppers, Dare 2b and Regatta Professional.",
  },
};

const mainRoutes = [
  {
    path: "/",
    name: "Home_Page",
    component: HomePage,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/all-about-us",
    name: "More_About_Us",
    component: AboutUs,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/work-with-us",
    name: "Work_With_Us",
    component: WorkWithUs,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/our-brands",
    name: "Our_Brands",
    component: OurBrands,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/where-we-are",
    name: "Where_We_Are",
    component: WhereWeAre,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/vacancies/",
    name: "Vacancies",
    component: AllVacancies,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/vacancies/vacancy/",
    name: "Vacancy",
    component: SingleVacancies,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/applicant-privacy-notice/",
    name: "ApplicantPrivacyNotice",
    component: ApplicantPrivacyNotice,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/cookie-policy/",
    name: "CookiePolicy",
    component: CookiePolicy,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/gender-pay-gap/",
    name: "GenderPayGap",
    component: GenderPayGap,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/modern-slavery-statement/",
    name: "ModernSlaveryStatement",
    component: ModernSlaveryStatement,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
];

const departmentRoutes = [
  {
    path: "/buying-and-product/",
    name: "DepartmentBuyingAndProduct",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/contact-centre/",
    name: "DepartmentContactCentre",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/wholesale-customer-service/",
    name: "DepartmentWholesaleCustomerService",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/design/",
    name: "DepartmentDesign",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/people-team/",
    name: "DepartmentPeopleTeam",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/imports/",
    name: "DepartmentImports",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/it/",
    name: "DepartmentIT",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/health-and-safety/",
    name: "DepartmentHealthAndSafety",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/digital/",
    name: "DepartmentDigital",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/finance/",
    name: "DepartmentFinance",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/merchandising/",
    name: "DepartmentMerchandising",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/marketing/",
    name: "DepartmentMarketing",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/procurement-and-legal/",
    name: "DepartmentProcurementAndLegal",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/retail-and-concessions/",
    name: "DepartmentRetailAndConcessions",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/sales/",
    name: "DepartmentSales",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/warehouse/",
    name: "DepartmentWarehouse",
    component: DepartmentComponent,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
];

const employeeProfileRoutes = [
  {
    path: "/buying-and-product/chelsea-norcross/",
    name: "EmployeeChelseaNorcross",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/buying-and-product/laura-buschini/",
    name: "EmployeeLauraBuschini",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/buying-and-product/vic-pugh/",
    name: "EmployeeVicPugh",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/contact-centre/becca-bolton/",
    name: "EmployeeBeccaBolton",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/contact-centre/chloe-siddeley/",
    name: "EmployeeChloeSiddeley",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/wholesale-customer-service/karen-howarth/",
    name: "EmployeeKarenHowarth",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/wholesale-customer-service/sam-mcgivern/",
    name: "EmployeeSamMcGivern",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/design/beth-warburton/",
    name: "EmployeeBethWarburton",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/design/hannah-bullock/",
    name: "EmployeeHannahBullock",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/design/jo-morfoot/",
    name: "EmployeeJoMorfoot",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/people-team/charlie-berrisford/",
    name: "EmployeeCharlieBerrisford",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/people-team/caron-wright/",
    name: "EmployeeCaronWright",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/imports/sam-mcdonald/",
    name: "EmployeeSamMcDonald",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/imports/gemma-marsh/",
    name: "EmployeeGemmaMarsh",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/it/anil-sunil-malkit/",
    name: "EmployeeAnilSunilMalkit",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/health-and-safety/bob-reid/",
    name: "EmployeeBobReid",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/digital/lena-mistry/",
    name: "EmployeeLenaMistry",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/digital/emily-goddard/",
    name: "EmployeeEmilyGoddard",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/finance/wani-syazwani-rohaizat/",
    name: "EmployeeWaniSyazwaniRohaizat",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/finance/joe-regan/",
    name: "EmployeeJoeRegan",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/merchandising/jay-thaker/",
    name: "EmployeeJayThaker",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/merchandising/lynsey-morton/",
    name: "EmployeeLynseyMorton",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/marketing/sally-bailey/",
    name: "EmployeeSallyBailey",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/marketing/cat-mather/",
    name: "EmployeeCatMather",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/marketing/david-sewell/",
    name: "EmployeeDavidSewell",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/procurement-and-legal/ketsia-leury-agarat/",
    name: "EmployeeKetsiaLeuryAgarat",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/procurement-and-legal/rachel-thomas/",
    name: "EmployeeRachelThomas",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/retail-and-concessions/kassie-eccles/",
    name: "EmployeeKassieEccles",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/sales/steve-lloyd/",
    name: "EmployeeSteveLLoyd",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/sales/mimi-ndaya/",
    name: "EmployeeMimiNdaya",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/sales/simon-tai-yip/",
    name: "EmployeeSimonTaiYip",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/warehouse/kasia-zareba/",
    name: "EmployeeKasiaZareba",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "/warehouse/adam-bishop/",
    name: "EmployeeAdamBishop",
    component: EmployeeProfile,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
];

const pageNotFoundRoutes = [
  {
    path: "/page-not-found/",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
  {
    path: "*",
    name: "PageNotFoundRedirect",
    redirect: "/page-not-found/",
    meta: {
      title: overarchingMeta.meta.title,
      metaTags: [
        { name: "description", content: overarchingMeta.meta.description },
        {
          property: "og:description",
          content: overarchingMeta.meta.description,
        },
      ],
    },
  },
];

const routes = [
  ...mainRoutes,
  ...departmentRoutes,
  ...employeeProfileRoutes,
  ...pageNotFoundRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
