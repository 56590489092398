<template>
  <div class="regatta-foundation-wrapper d-flex flex-wrap">
    <section class="regatta-foundation-video col-12 col-lg-6 pe-0 pe-lg-4 mb-5">
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          :src="
            contentfulComponents.theRegattaFoundationVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.theRegattaFoundationVideoTitle"
        ></iframe>
      </div>
    </section>

    <section class="regatta-foundation-content col-12 col-lg-6 ps-0 ps-lg-4">
      <h2
        v-if="contentfulComponents.theRegattaFoundationHeaderText"
        class="h4 mb-4"
      >
        {{ contentfulComponents.theRegattaFoundationHeaderText }}
      </h2>
      <div
        v-if="contentfulComponents.theRegattaFoundationBodyCopy"
        class="apprenticeships-header__text-container"
      >
        <template
          v-for="(
            textItem, index
          ) in contentfulComponents.theRegattaFoundationBodyCopy.split(/\n\n/g)"
        >
          <p :key="index">{{ textItem }}</p>
        </template>
      </div>
    </section>

    <section class="regatta-foundation-carousel col-12 p-0">
      <ImageCarousel
        v-if="contentfulComponents.theRegattaFoundationCarouselImages"
        :carouselImages="imagesData"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
import ImageCarousel from "../Commons/ImageCarousel";

export default {
  name: "TheRegattaFoundation",
  mixins: [ContentfulCMS],
  components: {
    ImageCarousel,
  },
  data() {
    return {};
  },
  computed: {
    imagesData() {
      if (this.contentfulComponents.theRegattaFoundationCarouselImages) {
        let contentData =
          this.contentfulComponents.theRegattaFoundationCarouselImages;
        contentData.sort((a, b) =>
          a.internalTitle > b.internalTitle
            ? 1
            : b.internalTitle > a.internalTitle
            ? -1
            : 0
        );
        return contentData;
      }
      return null;
    },
  },
  methods: {},
  created() {
    this.getContentful("4pYOx8gwdxYVXn6zap5EIj");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.regatta-foundation-video {
  border-right: 0;

  @include media-breakpoint-up(lg) {
    border-right: 1px solid map-get($colors, geyser);
  }
}

.regatta-foundation-content {
  h2 {
    font-weight: 400;
  }
}
</style>
