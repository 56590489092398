<template>
  <div class="values-wrapper d-flex flex-wrap">
    <div
      class="values-header w-100 mb-5"
      :style="
        'background-image: url(' + contentfulComponents.valuesHeaderImage + ')'
      "
    ></div>

    <section class="values-content__left col-12 col-md-6 pe-0 pe-md-3 pe-lg-4">
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          :src="
            contentfulComponents.valuesVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.valuesVideoTitle"
        ></iframe>
      </div>

      <div class="my-4" v-for="(element, index) in contentLeft" :key="index">
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
    </section>

    <section class="values-content__right col-12 col-md-6 ps-0 ps-md-3 ps-lg-4">
      <div
        class="mb-4"
        v-for="(element, index) in contentRightTop"
        :key="index"
      >
        <h2 class="h4 mb-4">{{ element.careersGenericTitle }}</h2>
        <div v-html="element.careersGenericText"></div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="col-12">
          <h2 class="h4 mb-4">
            {{ contentfulComponents.valuesContentBottomRightHeader }}
          </h2>
        </div>
        <div
          class="col-12"
          v-for="(element, index) in contentRightBottom"
          :key="index"
        >
          <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
            {{ element.careersGenericTitle }}
          </h2>
          <div v-html="element.careersGenericText"></div>
        </div>
        <!-- <img
          
          :src="contentfulComponents.valuesContentBottomRightImage"
          :alt="contentfulComponents.valuesContentBottomRightImageAltText"
        /> -->
        <picture>
          <source
            sizes="100vw"
            :srcset="
              contentfulComponents.valuesContentBottomRightImage + '?fm=avif'
            "
            type="image/avif"
          />
          <img
            :alt="contentfulComponents.valuesContentBottomRightImageAltText"
            class="values-bottom-image w-100 mb-3 mb-lg-4"
            :src="contentfulComponents.valuesContentBottomRightImage"
          />
        </picture>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Our_Values",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  computed: {
    contentLeft() {
      return this.contentElements(
        this.contentfulComponents.valuesContentLeftSection
      );
    },
    contentRightBottom() {
      return this.contentElements(
        this.contentfulComponents.valuesContentBottomRightSection
      );
    },
    contentRightTop() {
      return this.contentElements(
        this.contentfulComponents.valuesContentTopRightSection
      );
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("YZ1zDnEKX8H2GYZPgzvfw");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.values-wrapper {
  h2 {
    font-weight: 400;
  }

  .values-header {
    height: 200px;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(sm) {
      height: 300px;
    }

    @include media-breakpoint-up(lg) {
      height: 400px;
    }
  }

  .values-content__left {
    border-right: 0;

    @include media-breakpoint-up(md) {
      border-right: 1px solid map-get($colors, geyser);
    }
  }
}
</style>
