<template>
  <div class="p-0">
    <div class="benefits-content row m-0 flex-nowrap">
      <div
        v-for="(item, benefitIndex) in benefitsData"
        :key="benefitIndex"
        class="benefit-item col p-0 position-relative"
        :class="benefitIndex !== benefitsData.length - 1 ? 'me-3' : ''"
        :style="'background-image: url(' + benefitsImages[benefitIndex] + ');'"
      ></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Warehouse-Benefits",
  mixins: [ContentfulCMS],
  props: {
    benefitsData: {
      type: Array,
      required: true,
    },
    benefitsImages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.benefits-content {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  overflow-x: auto;

  .benefit-item {
    min-height: 315px;
    max-height: 500px;
    min-width: 269px;
    padding: 1em;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    scroll-snap-align: center;

    @include media-breakpoint-up(md) {
      min-height: 31vw;
      min-width: 26vw;
    }

    @include media-breakpoint-up(xl) {
      min-width: unset;
      min-height: unset;
      height: 26vw;
    }

    @media screen and (min-width: 1800px) {
      height: 500px;
    }
  }
}
</style>
