var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apprenticeships-container"},[_c('section',{staticClass:"w-100 apprenticeships-header",style:(_vm.showDesktopImage
          ? 'background: url(' +
            _vm.contentfulComponents.apprenticeshipsHeaderImageDesktop +
            ')'
          : 'background: url(' +
            _vm.contentfulComponents.apprenticeshipsHeaderImageMobile +
            ')')},[(_vm.contentfulComponents.apprenticeshipsHeaderText)?_c('div',{staticClass:"apprenticeships-header__text-container"},[_vm._l((_vm.contentfulComponents.apprenticeshipsHeaderText.split(/\n\n/g)),function(textItem,index){return [_c('p',{key:index},[_vm._v(_vm._s(textItem))])]})],2):_vm._e()]),_c('section',{staticClass:"apprenticeships-content d-flex flex-wrap mb-5"},_vm._l((_vm.employeeCards),function(cardData,index){return _c('div',{key:index,staticClass:"col-12 col-xxl-6 pt-5 ps-3 pe-3",class:index % 2 === 0 ? 'ps-lg-0 pe-lg-3' : 'ps-lg-3 pe-lg-0'},[_c('EmployeeCard',{attrs:{"cardData":cardData}})],1)}),0),_c('section',{staticClass:"apprenticeships-video"},[_c('div',{staticStyle:{"padding":"36.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":_vm.contentfulComponents.apprenticeshipsVideoUrl +
            '&badge=0&autopause=0&player_id=0&app_id=58479',"frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":"","title":_vm.contentfulComponents.apprenticeshipsVideoTitle}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }