var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav flex-nowrap overflow-auto justify-content-between border-top border-bottom my-4 px-3 px-md-4",attrs:{"role":"tablist"}},_vm._l((_vm.subMenuElements),function(subNavElement,index){return _c('li',{key:index,staticClass:"nav-item col flex-nowrap text-center",attrs:{"role":"presentation"}},[_c('div',{staticClass:"nav-link link-dark py-3 text-nowrap",class:_vm.toDepartment && subNavElement.contentId == 'departments'
            ? 'active'
            : !_vm.toDepartment && index === 0
            ? 'active'
            : '',attrs:{"id":subNavElement.contentId + '-tab',"data-bs-toggle":"tab","data-bs-target":'#' + subNavElement.contentId,"type":"button","role":"tab","aria-controls":"home","aria-selected":_vm.toDepartment && subNavElement.contentId == 'departments'
            ? 'true'
            : !_vm.toDepartment && index === 0
            ? 'true'
            : 'false'}},[_vm._v(" "+_vm._s(subNavElement.subNavText)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }