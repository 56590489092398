<template>
  <div class="training-development-wrapper d-flex flex-wrap">
    <section class="training-development-content__left col-12 col-xl-6 pe-xl-4">
      <div
        style="padding: 56.25% 0 0 0; position: relative"
        class="mb-3 mb-lg-4"
      >
        <iframe
          :src="
            contentfulComponents.trainingDevelopmentVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.trainingDevelopmentVideoTitle"
        ></iframe>
      </div>
      <img
        class="recruitment-top-image d-none d-md-block w-100 mb-3 mb-xl-4"
        :src="contentfulComponents.trainingDevelopmentImage"
        :alt="contentfulComponents.trainingDevelopmentImageAltText"
      />
    </section>

    <section
      class="training-development-content__right col-12 col-xl-6 ps-0 ps-xl-4"
    >
      <div class="mb-4" v-for="(element, index) in contentRight" :key="index">
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
      <div
        class="col-12 pb-4"
        v-for="(cardData, index) in employeeCards"
        :key="'0' + index"
      >
        <EmployeeCard :cardData="cardData" v-if="renderComponent" />
      </div>
      <img
        class="recruitment-top-image d-block d-md-none w-100 mb-3 mb-lg-4"
        :src="contentfulComponents.trainingDevelopmentImage"
        :alt="contentfulComponents.trainingDevelopmentImageAltText"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
import EmployeeCard from "../Commons/EmployeeCard";

export default {
  name: "Training-Development",
  mixins: [ContentfulCMS],
  components: {
    EmployeeCard,
  },
  data() {
    return {
      renderComponent: true,
    };
  },
  computed: {
    contentRight() {
      return this.contentElements(
        this.contentfulComponents.trainingDevelopmentRightTextContent
      );
    },
    employeeCards() {
      return this.contentElements(
        this.contentfulComponents.trainingDevelopmentEmployeeCards
      );
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("58wuRwubMYu0GCNgFPEwNg");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.training-development-wrapper {
  h2 {
    font-weight: 400;
  }

  .training-development-content__left {
    border-right: 0;

    @include media-breakpoint-up(md) {
      border-right: 1px solid map-get($colors, geyser);
    }
  }
}
</style>
