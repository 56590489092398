<template>
  <div class="row m-0 mb-5">
    <div
      class="col-12 col-md-6 p-0 pe-0 pe-md-3 pe-lg-4 image-container order-3 order-md-2"
    >
      <picture>
        <source
          sizes="100vw"
          :srcset="contentfulComponents.image + '?fm=avif'"
          type="image/avif"
        />
        <img
          :alt="contentfulComponents.imageAltText"
          class="w-100"
          :src="contentfulComponents.image"
        />
      </picture>
    </div>
    <div
      v-if="contentfulComponents.bodyCopy"
      class="col-12 col-md-6 px-3 ps-lg-4 order-2 order-md-3"
    >
      <template
        v-for="(textItem, index) in contentfulComponents.bodyCopy.split(
          /\n\n/g
        )"
      >
        <p :key="index">{{ textItem }}</p>
      </template>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-center order-1 p-0 mb-5">
      <div
        class="col-6 col-md px-3 mb-4 mb-md-0 d-flex align-items-center justify-content-center logo__item"
      >
        <img
          src="../../assets/logo/Regatta-Logo.jpg"
          alt="Regatta Great Outdoors Logo"
          class="w-100"
        />
      </div>
      <div
        class="col-6 col-md px-3 mb-4 mb-md-0 d-flex align-items-center justify-content-center logo__item"
      >
        <img
          src="../../assets/logo/Craghoppers-Logo.jpg"
          alt="Craghoppers Logo"
          class="w-100"
        />
      </div>
      <div
        class="col-6 col-md px-3 d-flex align-items-center justify-content-center logo__item"
      >
        <img
          src="../../assets/logo/Dare2b-logo.png"
          alt="Dare 2B Logo"
          class="w-100"
        />
      </div>
      <div
        class="col-6 col-md px-3 d-flex align-items-center justify-content-center logo__item"
      >
        <img
          src="../../assets/logo/Regatta_Professional-Logo.jpg"
          alt="Regatta Professional Logo"
          class="w-100"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Homepage-Body",
  mixins: [ContentfulCMS],
  props: {
    entryData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.getContentful(this.entryData);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.image-container {
  border-right: 0;

  @include media-breakpoint-up(md) {
    border-right: 1px solid map-get($colors, geyser);
  }
}

.left {
  @include media-breakpoint-down(md) {
    border-right: 1px solid map-get($colors, geyser);
  }
}

.logo__item {
  img {
    max-width: 200px;
  }
}
</style>
