import axios from "axios";

export default {
  props: {
    initEntryID: {
      type: String,
      required: false,
    },
    contentfulEntryID: {
      type: String,
      required: false,
    },
    slug: {
      type: String,
      required: false,
    },
  },
  mounted() {},
  data() {
    return {
      spaceID: "04bxhspaqqg2",
      accessKey: "Qlbw-SCvp2NOj1_tkmn0nCRRco_c8T0L5X5Yeq_H3tI",
      entryID: "",
      contentfulComponents: {},
      dataItem: "",
    };
  },
  computed: {},
  methods: {
    initContentful(initEntryID) {
      this.entryID = initEntryID;
      axios
        .get(this.getEndpointPath())
        .then((response) => {
          // console.log('init - ', response.data.fields)
          if (this.entryID === "unIedDu9ikpCRzf3AOP7O") {
            // homepage
            this.handleContentfulResponse(response.data.fields[this.dataItem]);
          } else {
            this.handleContentfulResponse(response.data.fields);
          }
        })
        .catch((e) => {
          return e;
        });
    },
    getContentful(entryData) {
      this.entryID = entryData;
      axios
        .get(this.getEndpointPath())
        .then((response) => {
          // console.log('get - ', response.data.fields)
          this.handleContentfulResponse(response.data.fields);
        })
        .catch((e) => {
          return e;
        });
    },
    getEndpointPath() {
      return "https://cdn.contentful.com/spaces/{{spaceID}}/entries/{{entryID}}?access_token={{accessKey}}"
        .replace("{{spaceID}}", this.spaceID)
        .replace("{{entryID}}", this.entryID)
        .replace("{{accessKey}}", this.accessKey);
    },
    handleContentfulResponse(resp) {
      this.contentfulComponents = resp;
      //console.log('resp - ', resp)

      for (const [key, value] of Object.entries(resp)) {
        if (Array.isArray(value)) {
          this.contentfulComponents[key] = [];
          value.forEach((element) => {
            this.getContentfulArray(key, element.sys.id);
          });
        } else if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          this.getContentfulObject(key, value.sys.id);
        }
      }
    },
    async getContentfulObject(key, objectData) {
      try {
        const response = await axios.get(
          "https://cdn.contentful.com/spaces/{{spaceID}}/assets/{{assetID}}?access_token={{accessKey}}"
            .replace("{{spaceID}}", this.spaceID)
            .replace("{{assetID}}", objectData)
            .replace("{{accessKey}}", this.accessKey)
        );

        // console.log(key, ' - ', response)
        this.contentfulComponents[key] =
          "https:" + response.data.fields.file.url;
        this.contentfulComponents.fileFormat =
          response.data.fields.file.contentType;
        return true;
      } catch (e) {
        return e;
      }
    },
    getContentfulArray(key, arrayData) {
      axios
        .get(
          "https://cdn.contentful.com/spaces/{{spaceID}}/entries/{{entryID}}?access_token={{accessKey}}"
            .replace("{{spaceID}}", this.spaceID)
            .replace("{{entryID}}", arrayData)
            .replace("{{accessKey}}", this.accessKey)
        )
        .then((response) => {
          // console.log(key, ' - ', response.data.fields)
          this.contentfulComponents[key].push(response.data.fields);
        })
        .catch((e) => {
          return e;
        });
    },
    async getImageData(id) {
      try {
        const response = await axios.get(
          "https://cdn.contentful.com/spaces/{{spaceID}}/assets/{{assetID}}?access_token={{accessKey}}"
            .replace("{{spaceID}}", this.spaceID)
            .replace("{{assetID}}", id)
            .replace("{{accessKey}}", this.accessKey)
        );

        return "https:" + response.data.fields.file.url;
      } catch (e) {
        return e;
      }
    },
  },
};
