<template>
  <div class="container px-3 px-md-5 my-5">
    <div
      class="row m-0 mx-auto justify-content-center align-items-center"
      :style="
        'background-image: url(' +
        contentfulComponents.careersPageNotFoundBackgroundImage +
        ')'
      "
    >
      <div
        class="page-not-found-container d-flex flex-column justify-content-center align-items-center py-5"
      >
        <h1 class="h3 text-center">
          {{ contentfulComponents.careersPageNotFoundHeader }}
        </h1>
        <p class="text-center my-4">
          {{ contentfulComponents.careersPageNotFoundCopy }}
        </p>
        <div class="col-12 d-flex flex-wrap justify-content-center">
          <router-link
            :to="String(contentfulComponents.careersPageNotFoundLeftButtonUrl)"
            class="btn rounded-pill py-2 px-4 mt-3 page_not_found-content__cta mx-3"
            :class="contentfulComponents.careersPageNotFoundLeftButtonClass"
            >{{
              contentfulComponents.careersPageNotFoundLeftButtonText
            }}</router-link
          >
          <router-link
            :to="String(contentfulComponents.careersPageNotFoundRightButtonUrl)"
            class="btn rounded-pill py-2 px-4 mt-3 page_not_found-content__cta mx-3"
            :class="contentfulComponents.careersPageNotFoundRightButtonClass"
            >{{
              contentfulComponents.careersPageNotFoundRightButtonText
            }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";

export default {
  name: "PageNotFound",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {
    this.initContentful("6IoZJ5F3gmAEK6QVFUs1m8"); // update to the entry ID for that page
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../css/partials/bs-overwrites/colors";

.row {
  height: 800px;
  background-size: cover;
  background-position: center;

  .page-not-found-container {
    background-color: map-get($colors, white_transparent);
    width: 90%;
    height: fit-content;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(lg) {
      width: 700px;
    }

    .page_not_found-content__cta {
      &.secondary {
        background-color: map-get($colors, white) !important;
        color: map-get($colors, black) !important;
      }

      &.primary {
        background-color: map-get($colors, black) !important;
        color: map-get($colors, white) !important;
      }
    }
  }
}

h1 {
  font-weight: 400 !important;
}
</style>
