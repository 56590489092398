<template>
  <div class="footer-container mt-5 pt-0 pt-md-5 border-top">
    <div class="container-fluid px-0 pb-0 pb-md-3">
      <div id="footer-accordion" class="accordion mb-4 px-0 px-md-5">
        <div class="row m-0">
          <div class="accordion-item col-12 col-md-4 col-xl-3 p-0">
            <h2
              class="accordion-header d-block d-md-none p0"
              id="about-accordionHeader"
            >
              <button
                class="accordion-button collapsed px-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#about-accordion"
                aria-expanded="false"
                aria-controls="about-accordion"
              >
                {{ contentfulComponents.footerColumnOneHeader }}
              </button>
            </h2>
            <div
              id="about-accordion"
              class="accordion-collapse collapse d-md-block"
              aria-labelledby="about-accordionHeader"
              data-bs-parent="#footerAccordion"
            >
              <h3 class="d-none d-md-block">
                {{ contentfulComponents.footerColumnOneHeader }}
              </h3>
              <ul class="list-unstyled ps-3 pe-2 ps-md-0 pe-lg-0 mb-3 mb-md-0">
                <li
                  v-for="(item, columnOneIndex) in contentColumnOne"
                  :key="columnOneIndex"
                >
                  <router-link :to="item.footerItemUrl">{{
                    item.footerItemText
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="accordion-item col-12 col-md-4 col-xl-3 p-0">
            <h2
              class="accordion-header d-block d-md-none"
              id="jobs-accordionHeader"
            >
              <button
                class="accordion-button collapsed px-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#jobs-accordion"
                aria-expanded="false"
                aria-controls="jobs-accordion"
              >
                {{ contentfulComponents.footerColumnTwoHeader }}
              </button>
            </h2>
            <div
              id="jobs-accordion"
              class="accordion-collapse collapse d-md-block"
              aria-labelledby="jobs-accordionHeader"
              data-bs-parent="#footerAccordion"
            >
              <h3 class="d-none d-md-block">
                {{ contentfulComponents.footerColumnTwoHeader }}
              </h3>
              <ul class="list-unstyled ps-3 pe-2 ps-md-0 pe-lg-0 mb-3 mb-md-0">
                <li
                  v-for="(item, columnTwoIndex) in contentColumnTwo"
                  :key="columnTwoIndex"
                >
                  <router-link :to="item.footerItemUrl">{{
                    item.footerItemText
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="accordion-item col-12 col-md-4 col-xl-3 p-0">
            <h2
              class="accordion-header d-block d-md-none"
              id="websites-accordionHeader"
            >
              <button
                class="accordion-button collapsed px-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#websites-accordion"
                aria-expanded="false"
                aria-controls="websites-accordion"
              >
                {{ contentfulComponents.footerColumnThreeHeader }}
              </button>
            </h2>
            <div
              id="websites-accordion"
              class="accordion-collapse collapse d-md-block"
              aria-labelledby="websites-accordionHeader"
              data-bs-parent="#footerAccordion"
            >
              <h3 class="d-none d-md-block">
                {{ contentfulComponents.footerColumnThreeHeader }}
              </h3>
              <ul class="list-unstyled ps-3 pe-2 ps-md-0 pe-lg-0 mb-3 mb-md-0">
                <li
                  v-for="(item, columnThreeIndex) in contentColumnThree"
                  :key="columnThreeIndex"
                >
                  <a :href="item.footerItemUrl" target="_blank">{{
                    item.footerItemText
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-section d-flex flex-wrap px-0 px-md-3 py-2">
      <div
        class="footer-copyright col-12 col-md-4 order-2 order-md-1 mt-4 mt-md-0 pt-2 pt-md-0"
      >
        <div class="d-md-flex justify-content-start">
          <span>{{ contentfulComponents.footerCopyrightText }}</span>
        </div>
      </div>
      <div class="footer-policies col-12 col-md order-1 order-md-2">
        <ul class="list-unstyled m-0 d-md-flex justify-content-end">
          <li
            v-for="(item, policiesIndex) in contentPolicies"
            :key="policiesIndex"
            :class="{
              'mb-2 mb-md-0 me-0 me-md-4 pe-0 pe-md-4 border-end':
                policiesIndex !== contentPolicies.length - 1,
            }"
          >
            <router-link :to="item.footerItemUrl">{{
              item.footerItemText
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "FooterComponent",
  mixins: [ContentfulCMS],
  data() {
    return {};
  },
  computed: {
    contentColumnOne() {
      return this.contentElements(this.contentfulComponents.footerColumnOne);
    },
    contentColumnTwo() {
      return this.contentElements(this.contentfulComponents.footerColumnTwo);
    },
    contentColumnThree() {
      return this.contentElements(this.contentfulComponents.footerColumnThree);
    },
    contentPolicies() {
      return this.contentElements(this.contentfulComponents.footerPolicies);
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.footerItemIndex) - parseFloat(b.footerItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("5HFmvscsqhVkClA6NYslzF");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.footer-container {
  background-color: map-get($colors, alabaster);
  border-top: 1px solid map-get($colors, geyser);

  a {
    color: map-get($colors, shark);
    text-decoration: none;
  }

  .accordion-item {
    background-color: inherit;
    border: none;
    border-bottom: 1px solid map-get($colors, geyser);

    &:last-of-type {
      border-radius: unset;
    }

    @include media-breakpoint-up(md) {
      border-bottom: none;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
    }

    .accordion-button {
      font-size: 18px;
      font-weight: 500;
      color: inherit;
      background-color: inherit;

      &::after {
        background-image: url("../../assets/icons/add-icon.svg");
      }

      &:not(.collapsed) {
        color: inherit;
        background-color: inherit;
        box-shadow: none;

        &::after {
          background-image: url("../../assets/icons/subtract-icon.svg");
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .bottom-section {
    @include media-breakpoint-up(md) {
      border-top: 1px solid map-get($colors, geyser);
    }

    .footer-copyright {
      text-align: center;

      @include media-breakpoint-down(md) {
        border-top: 1px solid map-get($colors, geyser);
      }
    }

    .footer-policies {
      text-align: center;
    }
  }
}
</style>
