<template>
  <div class="container px-3 px-md-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="applicant-privacy-notice-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>
        <div class="my-4">
          <iframe
            v-for="element in contentfulComponents.careersPageContent"
            :key="element.genericTextItemIndex"
            frameborder="0"
            :src="element.careersGenericText"
            style="width: 100%; height: 1000px"
            data-dashlane-frameid="3381"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";

export default {
  name: "ModernSlaveryStatement",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
  },
  created() {
    this.initContentful("7lFoncLbgjBfjaDepSZ1Z8"); // update to the entry ID for that page
  },
};
</script>
