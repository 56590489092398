<template>
  <nav class="navbar navbar-expand-lg border-bottom mb-0">
    <div class="container px-3 px-md-5">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasMenu"
        aria-controls="offcanvasMenu"
        aria-label="Toggle navigation"
        @click="menuToggle"
      >
        <span
          class="navbar-toggler-icon default-icon"
          :class="{ 'd-none': isOpen }"
        ></span>
        <span
          class="navbar-toggler-icon close-icon"
          :class="{ 'd-none': !isOpen }"
        ></span>
      </button>
      <router-link class="navbar-brand" to="/"
        ><img
          src="../../assets/logo/Regatta_Group-Logo.svg"
          alt="The Regatta Group"
          id="logo"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasMenu"
      /></router-link>
      <div v-if="isCollapsed" class="navbar-spacer"></div>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu">
        <div
          class="offcanvas-body"
          :class="{ open: isOpen }"
          id="navigation-container"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mt-2 mt-lg-0">
            <li
              v-for="(navElement, index) in menuElements"
              :key="index"
              class="nav-item mx-md-3"
              data-bs-dismiss="offcanvas"
              @click="backToFirst"
            >
              <router-link class="nav-link link-dark" :to="navElement.navUrl">{{
                navElement.navText
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navigation-container">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 mt-2 mt-lg-0">
          <li
            v-for="(navElement, index) in menuElements"
            :key="index"
            class="nav-item mx-md-2 mx-xl-3"
            @click="backToFirst"
          >
            <router-link
              class="nav-link link-dark px-md-1 px-xl-2"
              :to="navElement.navUrl"
              >{{ navElement.navText }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Main-Navigation",
  mixins: [ContentfulCMS],
  data() {
    return {
      isOpen: false,
      isCollapsed: false,
    };
  },
  computed: {
    menuElements() {
      if (this.contentfulComponents.menuContent) {
        let menuData = this.contentfulComponents.menuContent;
        menuData.sort(
          (a, b) => parseFloat(a.navItemIndex) - parseFloat(b.navItemIndex)
        );
        return menuData;
      }
      return null;
    },
  },
  methods: {
    menuToggle() {
      this.isOpen = !this.isOpen;
      this.navListeners();
    },
    watchWindowResize() {
      if (window.outerWidth < 992) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
    },
    navListeners() {
      document
        .querySelector(".offcanvas-backdrop")
        .addEventListener("click", () => {
          this.isOpen = false;
        });

      document.querySelector(".navbar-brand").addEventListener("click", () => {
        this.isOpen = false;
      });

      document.querySelectorAll(".nav-item").forEach((elm) => {
        elm.addEventListener("click", () => {
          this.isOpen = false;
        });
      });
    },
    backToFirst() {
      this.$emit("back-to-first");
    },
  },
  created() {
    this.getContentful("4RxS9OjNTLw0JOByFIbPHy");
  },
  mounted() {
    if (window.outerWidth < 992) {
      this.isCollapsed = true;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/partials/bs-overwrites/colors";

.navbar {
  #logo {
    width: 150px;
  }

  .navbar-nav li:last-of-type {
    margin-right: 0 !important;
  }

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      width: 25px;

      &.default-icon {
        background-image: url("../../assets/icons/burger-icon.svg");
      }

      &.close-icon {
        background-image: url("../../assets/icons/cross.svg");
      }
    }
  }

  .navbar-spacer {
    width: 47px;
  }

  .navbar-collapse {
    flex-grow: unset;
  }

  .offcanvas-start {
    position: fixed;
    top: 64px;
    width: 310px;

    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.navbar {
  .offcanvas-backdrop {
    top: 64px;
  }
}
</style>
