<template>
  <div class="container px-3 px-md-5 mb-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="work-with-us-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>
        <SubNavigation
          :subNavId="'2rCwR8iVyPRCa869YkKlTb'"
          :toDepartment="toDepartment"
        />
        <div class="mt-2 mt-md-4 px-0 px-sm-3 tab-content">
          <div
            class="tab-pane fade"
            :class="{ 'show active': !toDepartment }"
            id="benefits"
            role="tabpanel"
            aria-labelledby="benefits-tab"
          >
            <WorkBenefits />
          </div>
          <div
            class="tab-pane fade"
            :class="{ 'show active': toDepartment }"
            id="departments"
            role="tabpanel"
            aria-labelledby="departments-tab"
          >
            <OurDepartments />
          </div>
          <div
            class="tab-pane fade"
            id="apply-for-a-job"
            role="tabpanel"
            aria-labelledby="apply-for-a-job-tab"
          >
            <ApplyForAJob />
          </div>
          <div
            class="tab-pane fade"
            id="training-development"
            role="tabpanel"
            aria-labelledby="training-development-tab"
          >
            <TrainingDevelopment />
          </div>
          <div
            class="tab-pane fade"
            id="recruitment"
            role="tabpanel"
            aria-labelledby="recruitment-tab"
          >
            <OurRecruitment />
          </div>
          <div
            class="tab-pane fade"
            id="apprenticeships"
            role="tabpanel"
            aria-labelledby="apprenticeships-tab"
          >
            <Apprenticeships />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";
import SubNavigation from "../components/Navigation/SubNavigation";
import WorkBenefits from "../components/WorkForUs/WorkBenefits";
import OurDepartments from "../components/WorkForUs/OurDepartments";
import ApplyForAJob from "../components/WorkForUs/ApplyForAJob";
import TrainingDevelopment from "../components/WorkForUs/TrainingDevelopment";
import OurRecruitment from "../components/WorkForUs/OurRecruitment";
import Apprenticeships from "../components/WorkForUs/Apprenticeships";

export default {
  name: "Work_With_Us",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
    SubNavigation,
    WorkBenefits,
    OurDepartments,
    ApplyForAJob,
    TrainingDevelopment,
    OurRecruitment,
    Apprenticeships,
  },
  props: {
    toDepartment: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.initContentful("WLvDbUCuP2mFhj6dZ4qmw"); // update to the entry ID for that page
    this.dataItem = "careersWorkWithUs"; // to use within the init response to get other entries for that page
  },
};
</script>
