var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cardData)?_c('div',{staticClass:"card_container d-flex flex-wrap p-3 border border-dark h-100"},[(!_vm.loading)?_c('img',{staticClass:"col-12 col-md-5 col-lg-4 me-0 me-md-3",attrs:{"src":_vm.contentfulComponents.employeeImage,"alt":_vm.cardData.name}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-content-between col me-0 me-md-3 ps-0 ps-md-3"},[_c('div',[(_vm.cardData.role)?_c('h3',{staticClass:"d-none d-md-block h5 mb-3"},[_vm._v(" "+_vm._s(_vm.cardData.name + " - " + _vm.cardData.role)+" ")]):_c('h3',{staticClass:"d-none d-md-block h5 mb-3"},[_vm._v(_vm._s(_vm.cardData.name))]),_c('h3',{staticClass:"d-block d-md-none mb-0 pt-3"},[_vm._v(_vm._s(_vm.cardData.name))]),(_vm.cardData.role)?_c('h3',{staticClass:"d-block d-md-none h4 mb-3"},[_vm._v(" "+_vm._s(_vm.cardData.role)+" ")]):_vm._e(),(_vm.cardData.buttonLink && _vm.cardData.bodyText)?_c('div',[_vm._l((_vm.trimmedText
              .substring(
                0,
                Math.min(_vm.trimmedText.length, _vm.trimmedText.lastIndexOf(' '))
              )
              .concat('...')
              .split(/\n\n/g)),function(textItem,index){return [_c('p',{key:index},[_vm._v(_vm._s(textItem))])]})],2):(_vm.cardData.bodyText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.cardData.bodyText)}}):_vm._e()]),_c('div',{staticClass:"align-self-end"},[(_vm.cardData.buttonLink)?_c('router-link',{staticClass:"btn rounded-pill py-2 px-5",class:_vm.cardData.buttonClass,attrs:{"to":String(_vm.cardData.buttonLink)}},[_vm._v(" "+_vm._s(_vm.cardData.buttonText)+" ")]):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }