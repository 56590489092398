<template>
  <div class="home container px-3 px-md-5">
    <div v-for="(elm, index) in contentfulComponents" :key="index">
      <component
        :is="returnEntryTemplate(elm.sys.id)"
        :entryData="elm.sys.id"
      ></component>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import HomepageHeader from "../components/Homepage/HomepageHeader";
import HomepageBody from "../components/Homepage/HomepageBody";
import HomepageVideo from "../components/Homepage/HomepageVideo";

export default {
  name: "Home-Page",
  mixins: [ContentfulCMS],
  components: {
    HomepageHeader,
    HomepageBody,
    HomepageVideo,
  },
  data() {
    return {};
  },
  methods: {
    returnEntryTemplate(entryID) {
      if (entryID == "5iQlxEepl9M8GWZu7D11N4") {
        return "HomepageHeader";
      } else if (entryID == "6Sr50HyiyTbDotE55r2asA") {
        return "HomepageBody";
      } else if (entryID == "56B8OMknJ0IN7FCqSoqaYf") {
        return "HomepageVideo";
      }
    },
  },
  mounted() {},
  created() {
    this.initContentful("unIedDu9ikpCRzf3AOP7O"); // update to the entry ID for that page
    this.dataItem = "careersPageContent"; // to use within the init response to get other entries for that page
  },
  computed: {},
};
</script>
