<template>
  <ul
    class="nav flex-nowrap overflow-auto justify-content-between border-top border-bottom my-4 px-3 px-md-4"
    role="tablist"
  >
    <li
      v-for="(subNavElement, index) in subMenuElements"
      :key="index"
      class="nav-item col flex-nowrap text-center"
      role="presentation"
    >
      <div
        class="nav-link link-dark py-3 text-nowrap"
        :class="
          toDepartment && subNavElement.contentId == 'departments'
            ? 'active'
            : !toDepartment && index === 0
            ? 'active'
            : ''
        "
        :id="subNavElement.contentId + '-tab'"
        data-bs-toggle="tab"
        :data-bs-target="'#' + subNavElement.contentId"
        type="button"
        role="tab"
        aria-controls="home"
        :aria-selected="
          toDepartment && subNavElement.contentId == 'departments'
            ? 'true'
            : !toDepartment && index === 0
            ? 'true'
            : 'false'
        "
      >
        {{ subNavElement.subNavText }}
      </div>
    </li>
  </ul>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Sub-Navigation",
  mixins: [ContentfulCMS],
  data() {
    return {};
  },
  props: {
    subNavId: {
      type: String,
      required: false,
    },
    toDepartment: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    subMenuElements() {
      if (this.contentfulComponents.subMenuContent) {
        let subMenuData = this.contentfulComponents.subMenuContent;
        subMenuData.sort(
          (a, b) =>
            parseFloat(a.subNavItemIndex) - parseFloat(b.subNavItemIndex)
        );
        return subMenuData;
      }
      return null;
    },
  },
  methods: {},
  created() {
    this.getContentful(this.subNavId);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

h1 {
  font-weight: 400 !important;
}

li {
  .active {
    border-bottom: 1px solid map-get($colors, black);
  }
}

.nav-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
