<template>
  <div class="container px-0 mb-5">
    <div class="department__container row m-0 my-5">
      <div
        class="department__header-container p-0 mx-auto position-relative"
        :style="
          showDesktopImage
            ? 'background-image: url(' +
              contentfulComponents.headerImageDesktop +
              ')'
            : 'background-image: url(' +
              contentfulComponents.headerImageMobile +
              ')'
        "
      >
        <button @click="backToDepartment" class="position-absolute back-btn">
          Back
        </button>
        <div
          class="department__header-container--content container mx-0 mx-lg-5 px-3 px-md-5 pt-3 pb-5 d-flex flex-column"
        >
          <div class="department__header-container--content-container cl-white">
            <h1 class="department-header">
              {{ contentfulComponents.header }}
            </h1>
          </div>
          <div class="pe-3 cl-white weight-300 department__details">
            <p class="m-0">{{ contentfulComponents.text }}</p>
          </div>
        </div>
      </div>
      <section class="department-content d-flex flex-wrap mb-5">
        <div
          class="col-12 col-xxl-6 pt-5 ps-3 pe-3"
          v-for="(cardData, index) in employeeCards"
          :key="index"
          :class="index % 2 === 0 ? 'ps-lg-0 pe-lg-3' : 'ps-lg-3 pe-lg-0'"
        >
          <EmployeeCard :cardData="cardData" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../../mixin/ContentfulCMS";
import EmployeeCard from "../Commons/EmployeeCard";

export default {
  name: "Department-Component",
  mixins: [ContentfulCMS],
  components: {
    EmployeeCard,
  },
  data() {
    return {
      contentfulIDs: [
        { path: "/buying-and-product/", id: "3uIvAMzaUCMfqBTrZRcsek" },
        { path: "/contact-centre/", id: "1cjUKwDBi2OlwBdHiARuPa" },
        { path: "/wholesale-customer-service/", id: "3Y2jqmKy9qmioHEZyZmf10" },
        { path: "/design/", id: "62f0wgDgXrFmKdMnPtEVB3" },
        { path: "/people-team/", id: "3lSJFtflHqY21gLGQk7lSK" },
        { path: "/imports/", id: "48GqjQHI0KPDa4vUz2JaD" },
        { path: "/it/", id: "5Hp19DTwUkWSQMLQESQ9S5" },
        { path: "/health-and-safety/", id: "1ITGYzU0AwkLdHZsHXCKOQ" },
        { path: "/digital/", id: "UAE6z5w44JtfcQVluoFaI" },
        { path: "/finance/", id: "5JT6GVmC5sfCExuANnSB35" },
        { path: "/merchandising/", id: "73zMtycUBQhYIH7dIgcnHo" },
        { path: "/marketing/", id: "B4fhdkkukXFcsryznznaC" },
        { path: "/procurement-and-legal/", id: "43Hs0B61iUdCOj99U8WUQz" },
        { path: "/retail-and-concessions/", id: "3A2CDwr9pikFU5IKlQd1Aa" },
        { path: "/sales/", id: "ggzPS0o3CWZ1PAicP6baY" },
        { path: "/warehouse/", id: "7yYyzEN4qBkPQD1nNovRxM" },
      ],
      showDesktopImage: false,
    };
  },
  computed: {
    currentPath() {
      return this.contentfulIDs.filter((id) => id.path == this.$route.path);
    },
    employeeCards() {
      return this.contentElements(this.contentfulComponents.peopleProfile);
    },
  },
  methods: {
    watchWindowResize() {
      if (window.outerWidth <= 992) {
        this.showDesktopImage = false;
      } else {
        this.showDesktopImage = true;
      }
    },
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort((a, b) =>
          a.employeeName > b.employeeName
            ? 1
            : b.employeeName > a.employeeName
            ? -1
            : 0
        );
        return contentData;
      }
      return null;
    },
    backToDepartment() {
      this.$router.go(-1);
      this.$emit("back-to-department");
    },
  },
  created() {
    this.initContentful(this.currentPath[0].id); // update to the entry ID for that page
  },
  mounted() {
    if (window.outerWidth >= 992) {
      this.showDesktopImage = true;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.department__container {
  @include media-breakpoint-down(lg) {
    padding-top: 40px;
  }

  .department__header-container {
    display: flex;
    height: 500px;
    background-position: center top;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);

    .back-btn {
      position: relative;
      background: transparent;
      border: none;
      color: map-get($colors, black);
      margin-left: 50px;
      margin-top: -40px;

      &:before {
        content: "<";
        display: inline-block;
        width: 30px;
        height: 30px;
        left: -32px;
        position: absolute;
        border: 1px solid map-get($colors, black);
        border-radius: 50%;
        margin-top: -4px;
        padding-top: 3px;
      }

      @include media-breakpoint-up(lg) {
        color: white;
        margin-top: 30px;
        margin-left: 128px;

        &:before {
          border: 1px solid map-get($colors, white);
        }
      }
    }

    .department__header-container--content {
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        justify-content: center;
      }

      .department__header-container--content-container {
        margin-top: 10rem;

        .department-header {
          font-size: calc(1.8rem + 1.5vw);
          font-weight: 200 !important;

          @include media-breakpoint-up(xl) {
            font-size: 3.5rem;
          }
        }
      }
    }
  }

  .department-content {
    @media only screen and (min-width: 1801px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
