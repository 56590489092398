<template>
  <div>
    <div id="singleVacancy">
      <div class="row m-0" id="vacanciesRow">
        <div
          class="header-container p-0 mx-auto"
          :style="
            'background-image: url(' + contentfulComponents.headerImage + ')'
          "
        >
          <div
            class="container px-3 px-md-5 pt-3 pb-5 d-flex align-content-around align-content-md-between flex-wrap"
          >
            <div class="col-12">
              <button
                @click="$router.go(-1)"
                class="back-btn d-none d-md-block"
              >
                Back
              </button>

              <div class="cl-white header-title">
                <h2 class="job-location weight-300">
                  <span class="vf-category"></span>
                </h2>
                <h1 class="vf-jobtitle weight-400"></h1>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-between align-items-start align-sm-items-center flex-column flex-sm-row"
            >
              <div class="pe-3 cl-white weight-300 location__details">
                <span class="vf-region"></span>,
                <span class="vf-Location"></span>,
                <span class="vf-country"></span>
              </div>
              <div class="vf-ApplyButton-bottom mt-3 mt-sm-0"></div>
            </div>
          </div>
        </div>

        <div class="container px-3 px-md-5 flex-column flex-xl-row">
          <div class="column-1 box p-0 ms-0 mt-5 mb-0 order-2 order-xl-1">
            <div>
              <div class="vf-job-detail-wrap"></div>
              <!-- <div class="vf-break"></div> -->
              <div class="vf-JobDescription" style="padding-top: 20px">
                Loading...
              </div>
              <br />
              <hr />
              <div class="btn-bottom">
                <div class="vf-ApplyButton-bottom"></div>

                <button
                  title="Playback the job description"
                  class="audio_btn"
                  onclick="if(this.classList.contains('active')) {                                                    
                this.classList.remove('active')
                window.playCard(`stopped`, 
                'en-US')
              } else if(!this.classList.contains('active')){
                this.classList.add('active'); 
                window.playCard(` 
                  ${document.querySelector('.vf-jobtitle').textContent},
                  'The job reference for this role is:' ${document.querySelector('.vf-AdvertReference').textContent},
                  'The category of this job is:' ${document.querySelector('.vf-category').textContent},
                  'Location:' ${document.querySelector('.vf-Location').textContent},
                  'Contract Type:' ${document.querySelector('.vf-JobType').textContent},
                  ${ document.querySelector('.vf-JobTypeTime').textContent},
                  'Salary:' ${document.querySelector('.vf-Salary').textContent},
                  'Closing Date:' ${document.querySelector('.vf-ExpiryDate').textContent},
                  ${document.querySelector('.vf-JobDescription').textContent}, 
                `, 
                'en-US')
              }"
                >
                  Play Description
                </button>
              </div>
            </div>
          </div>
          <div
            class="column-2 box px-0 ps-0 ps-xl-4 pt-5 pb-0 m-0 order-1 order-xl-2 rounded-0"
          >
            <div>
              <div class="vf-details-wrap">
                <h2 style="padding: 10px">
                  <strong>Details</strong>
                </h2>
                <ul class="vf-position">
                  <div id="map" class="map-container"></div>
                  <li>
                    <strong>Job Ref: </strong
                    ><span class="vf-AdvertReference"></span>
                  </li>
                  <div class="vf-break job-detail-item-spacing"></div>
                  <li>
                    <strong>Category: </strong><span class="vf-category"></span>
                  </li>
                  <div class="vf-break job-detail-item-spacing"></div>
                  <li>
                    <strong>Location: </strong
                    ><span class="vf-Location"></span>,
                    <span class="vf-postcode"></span>
                  </li>
                  <div class="vf-break job-detail-item-spacing"></div>
                  <li>
                    <strong>Contract Type: </strong
                    ><span class="vf-JobType"></span>&nbsp;/&nbsp;<span
                      class="vf-JobTypeTime"
                    ></span>
                    <div class="JobDuration-wrap" hidden>
                      (
                      <span class="vf-JobDuration"></span>
                      )
                    </div>
                  </li>
                  <div class="vf-break job-detail-item-spacing"></div>
                  <li>
                    <strong>Salary: </strong><span class="vf-Salary"></span>
                  </li>
                  <div class="vf-break job-detail-item-spacing"></div>
                  <li>
                    <strong>Closing Date: </strong
                    ><span class="vf-ExpiryDate"></span>
                  </li>
                </ul>

                <ul class="vf-Attachments"></ul>
                <div
                  class="vf-ApplyButton-sidebar"
                  style="padding-bottom: 10px"
                ></div>
                <!-- <a
              href="/careers"
              class="vf-apply-button ui button btn btn-primary"
              id="vacancy-page-back-search-button"
              >Return to listings</a
            > -->
                <h3 class="shareHeader" style="padding-top: 20px">Share</h3>
                <div class="vf-break"></div>
                <br />
                <div id="share-icons">
                  <div class="vf-SocialButtons"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";

export default {
  name: "SingleVacancy",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  created() {
    this.initContentful("4ldAi8PRyVO585XA1G2Azq"); // update to the entry ID for that page

    let VFSmartFeed = document.createElement("script");
    VFSmartFeed.setAttribute(
      "src",
      "https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/Regatta_New_Smartfeed/ThreeCardDesign/vf-settings-mockup.js"
    );
    document.head.appendChild(VFSmartFeed);

    let VFMainJS = document.createElement("script");
    VFMainJS.setAttribute(
      "src",
      "https://new-smart-feed.vacancy-filler.co.uk/Main_Project_Files/vf-single.min.js"
    );
    document.head.appendChild(VFMainJS);
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../css/partials/bs-overwrites/colors";

#singleVacancy:deep(#vacanciesRow) {
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 1rem;
    max-width: 1200px;
    margin: 30px auto;
  }

  .container {
    display: flex;
  }

  .column-1 {
    flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
    flex-basis: 70%; /* sets initial width to 70% */
  }

  .column-2 {
    @media only screen and (min-width: 1201px) {
      -webkit-box-shadow: -5px 0px 5px 0px rgb(0 0 0 / 30%);
      -moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .box {
    padding: 10px;
    border-radius: 12px;

    margin: 1rem;
  }
  @media only screen and (max-width: 900px) {
    .box {
      margin: 0 0 1rem;
    }
  }

  .company-logo-wrap {
    padding: 10px 0px;
  }

  .icon:before {
    font: inherit !important;
  }

  .back-btn {
    position: relative;
    background: transparent;
    border: none;
    color: map-get($colors, white);
    left: 34px;
    top: 14px;

    &:before {
      content: "<";
      display: inline-block;
      width: 30px;
      height: 30px;
      left: -32px;
      position: absolute;
      border: 1px solid map-get($colors, white);
      border-radius: 50%;
      margin-top: -4px;
      padding-top: 3px;
    }
  }

  .vf-flex-row {
    display: flex;
  }

  .vf-jobtitle {
    margin: 0px;
    font-size: 3em;

    @media only screen and (min-width: 768px) {
      font-size: 3.5em;
    }
  }

  .job-location {
    font-size: 1.5em;

    @media only screen and (min-width: 768px) {
      font-size: 2em;
    }
  }

  .location__details {
    font-size: 1.2em;
  }

  .vf-job-detail-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .vf-position li {
    padding: 5px 0px;

    strong {
      font-weight: 500;
      margin-right: 10px;
    }
  }

  .vf-position {
    list-style: none;
    padding: 10px 0px;
    margin: 0px;
  }

  .vf-details-wrap {
    width: 100%;

    h2 {
      padding: 0 !important;

      strong {
        font-weight: 500;
      }
    }

    .ui.button {
      background: map-get($colors, black) !important;
      color: map-get($colors, white) !important;
    }
  }

  div#map {
    height: 220px;
    width: 100%;
    margin-bottom: 15px;
  }

  .vf-Attachments {
    list-style-type: none;
    padding: 0px;
    margin-left: 0rem !important;
    border: map-get($colors, black);
    border-style: solid;
  }

  .vf-Attachments .filetype {
    display: inline-block;
    width: auto;
    line-height: 10px !important;
  }

  .vf-Attachments .details {
    width: auto;
    display: inline-block !important;
    padding: 0px;
    float: none !important;
    line-height: 15px !important;
    background: transparent;
  }

  a.btn-filedownload.vf-row {
    color: map-get($colors, black);
    padding: 1rem !important;
    width: 100%;
    display: inline-block;
  }

  .vf-Attachments li {
    margin-bottom: 5px;
    margin: 0;
    justify-content: center;
  }

  .vf-apply-button {
    width: 100% !important;
    background: map-get($colors, white) !important;
    border: 1px solid map-get($colors, white) !important;
    color: map-get($colors, black) !important;
    padding: 15px 50px !important;
    border-radius: 0.2em !important;
    margin: 0 !important;
  }

  .vf-apply-button:hover {
    background: map-get($colors, white) !important;
    color: map-get($colors, mine_shaft) !important;
  }

  .vf-SocialButtons {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }

  .vf-SocialButtons a {
    font-size: 35px;
    text-align: center;
  }

  img.vf-img-wrap {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
  }

  .vf-JobDescription {
    padding-top: 0 !important;
    strong {
      font-weight: 600;
    }
  }

  .vf-JobDescription ul,
  .vf-JobDescription ol {
    line-height: 1.4em;
  }

  .vf-break {
    border-bottom: 2px solid map-get($colors, gallery);
    width: calc(100% - 2rem);
  }

  .vf-jobdetails {
    margin: 0px;
  }

  .shareHeader {
    margin-top: 10px;
  }

  @media (max-width: 668px) {
    .vf-job-detail-wrap {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    .vf-flex-row {
      display: block;
    }
  }

  .audio_btn {
    background-color: map-get($colors, mine_shaft);
    border: none;
    border-radius: 0.3em;
    color: map-get($colors, white);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .btn-bottom {
    align-items: flex-end;
  }

  span.facebook.icon,
  span.twitter.square.icon,
  span.linkedin.icon,
  span.envelope.square.icon,
  span.print.icon {
    cursor: pointer;
    color: map-get($colors, black);
  }

  span.icon {
    &:before {
      content: "" !important;
      width: 40px;
      height: 40px;
      display: block;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }

    &.facebook {
      &:before {
        background-image: url(../assets/icons/facebook.svg) !important;
      }
    }

    &.twitter {
      &:before {
        background-image: url(../assets/icons/twitter.svg) !important;
      }
    }

    &.linkedin {
      &:before {
        background-image: url(../assets/icons/linkedin.svg) !important;
      }
    }

    &.envelope {
      &:before {
        background-image: url(../assets/icons/Email.svg) !important;
      }
    }

    &.print {
      &:before {
        background-image: url(../assets/icons/Printer.svg) !important;
      }
    }
  }

  /* custom styling*/
  .job-detail-item-spacing {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header-container {
    display: flex;
    max-width: 1980px;
    height: 500px;
    background-position: center;
    background-size: cover;
  }
  .header-title {
    @media only screen and (min-width: 768px) {
      margin-top: 100px;
    }
  }
  .ui.button,
  .ui.buttons .button,
  .ui.buttons .or {
    font-size: 1rem !important;
    border-style: solid !important;
    border-radius: 50px !important;
  }
  @media only screen and (max-width: 760px) {
    .vf-apply-button.ui.button.btn.btn-primary {
      margin: 7px;
    }
  }

  .audio_btn.active .fa-volume-up:before {
    color: map-get($colors, thunderbird) !important;
  }
  .audio_btn.active {
    background: map-get($colors, barley_corn) !important;
  }

  .benefits__section {
    background-size: cover;
    background-position: center;
    height: 300px;
    max-height: 364px;
    max-width: 1980px;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    overflow-x: auto;

    @media only screen and (min-width: 999px) {
      height: 20vw;
    }
  }
}
</style>
