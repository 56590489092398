<template>
  <div class="regatta-sustainability-wrapper d-flex flex-wrap">
    <section
      class="regatta-sustainability__content-left col-12 col-lg-6 pe-0 pe-lg-4 mb-5"
    >
      <div v-for="(element, index) in textContent" :key="index">
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
    </section>
    <section
      class="regatta-sustainability__content-right col-12 col-lg-6 ps-0 ps-lg-4"
    >
      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          :src="
            contentfulComponents.sustainabilityVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.sustainabilityVideoTitle"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Sustainability-At-Regatta",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  computed: {
    textContent() {
      return this.contentElements(
        this.contentfulComponents.sustainabilityTextContent
      );
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("4rHQSHai99I43FesmvXlp6");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.regatta-sustainability__content-left {
  border-right: 0;

  @include media-breakpoint-up(lg) {
    border-right: 1px solid map-get($colors, geyser);
  }

  h2 {
    font-weight: 400;
  }
}
</style>
