<template>
  <div v-if="carouselImages" class="carousel_container container p-0">
    <div v-if="!loading" class="glide" id="image__carousel">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <li
            class="glide__slide"
            v-for="(item, imageIndex) in imagesData"
            :key="imageIndex"
          >
            <img :src="item" class="w-100" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
import Glide from "@glidejs/glide";

export default {
  name: "Image-Carousel",
  mixins: [ContentfulCMS],
  props: {
    carouselImages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      imagesData: {},
      carousel: Glide,
      allowGlideToInitiate: true,
    };
  },
  computed: {},
  methods: {
    async fetchData(data) {
      let images = {};
      let index = 0;
      for (const element of data) {
        for (const [elementKey, elementValue] of Object.entries(element)) {
          if (
            typeof elementValue === "object" &&
            elementValue !== null &&
            !Array.isArray(elementValue)
          ) {
            console.log(elementKey);
            const imageUrl = await this.getImageData(elementValue.sys.id);
            images[index] = imageUrl;
            index++;
          }
        }
      }
      this.imagesData = images;
    },
    initiateGlide() {
      this.carousel = new Glide("#image__carousel", {
        type: "carousel",
        startAt: 0,
        perView: 4, // how many carousel items to show
        gap: 10, // the margin between the carousel items
        autoplay: 3000, // set in milliseconds. If number then autoplay to that value, set to 'false' if not needed
        animationDuration: 700, // set in milliseconds
        peek: {
          // how much of the previous/next image to show in the carousel
          before: 0, // how much to show of previous image
          after: 100, // how much to show of next image
        },
        breakpoints: {
          // breakpoint for mobile so we can change the options if needed
          768: {
            perView: 1,
            gap: 10,
            autoplay: 3000,
            animationDuration: 700,
            peek: {
              before: 0,
              after: 100,
            },
          },
        },
      });
      this.carousel.mount();
    },
  },
  async beforeUpdate() {
    if (this.loading) {
      await this.fetchData(this.carouselImages);
    }
    this.loading = false;
    setTimeout(() => {
      if (this.allowGlideToInitiate) {
        this.initiateGlide();
      }
    }, 2000);
  },
  mounted() {},
  destroyed() {
    this.allowGlideToInitiate = false;
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "@glidejs/glide/src/assets/sass/glide.core";
@import "../../css/partials/bs-overwrites/colors";
</style>
