<template>
  <div class="container px-3 px-md-5 mb-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="our-brands-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>
        <SubNavigation :subNavId="'4zse2WFyABBj2hYaMak2ze'" />
        <div class="mt-2 mt-md-4 px-0 px-sm-3 tab-content">
          <div
            class="tab-pane fade show active"
            id="regatta"
            role="tabpanel"
            aria-labelledby="regatta-tab"
          >
            <BrandPage :brandId="'2b2FtujytAi0sbVbBRfsQW'" />
          </div>
          <div
            class="tab-pane fade"
            id="craghoppers"
            role="tabpanel"
            aria-labelledby="craghoppers-tab"
          >
            <BrandPage :brandId="'5kfHQ5sl3KKRTHzI3wWKl7'" />
          </div>
          <div
            class="tab-pane fade"
            id="dare2b"
            role="tabpanel"
            aria-labelledby="dare2b-tab"
          >
            <BrandPage :brandId="'1znGIZD2EpS8MjHwRok5wD'" />
          </div>
          <div
            class="tab-pane fade"
            id="regatta-professional"
            role="tabpanel"
            aria-labelledby="professional-tab"
          >
            <BrandPage :brandId="'30HmObuaxVFnwRnL9geZfb'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";
import SubNavigation from "../components/Navigation/SubNavigation";
import BrandPage from "../components/Commons/BrandPage";

export default {
  name: "Our-Brands",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
    SubNavigation,
    BrandPage,
  },
  created() {
    this.initContentful("47LSHFyIKrR3xFftsevFpl"); // update to the entry ID for that page
    this.dataItem = "brands"; // to use within the init response to get other entries for that page
  },
};
</script>
