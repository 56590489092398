var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 departments-container container p-0 mt-5"},[_c('ul',{staticClass:"list-unstyled row g-2 g-lg-3"},_vm._l((_vm.departments),function(item,departmentsIndex){return _c('li',{key:departmentsIndex,staticClass:"departments__item d-flex col-6 col-sm-4 col-lg-3"},[_c('div',{staticClass:"d-flex w-100 h-100",style:('background-color:' +
            _vm.contentfulComponents.departmentsBackgroundColour +
            ';')},[_c('div',{staticClass:"w-100 h-100",style:('background-color: rgba(255 255 255 / ' +
              (40 -
                (departmentsIndex + 1) *
                  (40 / _vm.contentfulComponents.departments.length)) +
              '%);')},[_c('router-link',{staticClass:"d-flex w-100 h-100 justify-content-center align-items-center text-decoration-none text-center",style:('color: ' + _vm.contentfulComponents.departmentsTextColour + ';'),attrs:{"to":item.link}},[_vm._v(_vm._s(item.departmentName))])],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }