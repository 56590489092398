<template>
  <div class="w-100 departments-container container p-0 mt-5">
    <ul class="list-unstyled row g-2 g-lg-3">
      <li
        v-for="(item, departmentsIndex) in departments"
        :key="departmentsIndex"
        class="departments__item d-flex col-6 col-sm-4 col-lg-3"
      >
        <div
          class="d-flex w-100 h-100"
          :style="
            'background-color:' +
            contentfulComponents.departmentsBackgroundColour +
            ';'
          "
        >
          <div
            class="w-100 h-100"
            :style="
              'background-color: rgba(255 255 255 / ' +
              (40 -
                (departmentsIndex + 1) *
                  (40 / contentfulComponents.departments.length)) +
              '%);'
            "
          >
            <router-link
              :to="item.link"
              class="d-flex w-100 h-100 justify-content-center align-items-center text-decoration-none text-center"
              :style="
                'color: ' + contentfulComponents.departmentsTextColour + ';'
              "
              >{{ item.departmentName }}</router-link
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Our-Departments",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  computed: {
    departments() {
      if (this.contentfulComponents.departments) {
        let departmentsData = this.contentfulComponents.departments;
        departmentsData.sort(
          (a, b) => parseFloat(a.departmentId) - parseFloat(b.departmentId)
        );
        return departmentsData;
      }
      return null;
    },
  },
  methods: {},
  created() {
    this.getContentful("4eBEaUC2igQNJM63FZTr72");
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.departments__item {
  &::before {
    content: "";
    padding: 50% 0;
  }
}
</style>
