<template>
  <div id="app">
    <Navigation @back-to-first="backToFirst" />
    <router-view
      @back-to-department="backToDepartment"
      :toDepartment="toDepartment"
    />
    <FooterComponent />
  </div>
</template>

<style lang="scss">
@import "./css/root.scss";
</style>

<script>
// @ is an alias to /src
import Navigation from "./components/Navigation/Header";
import FooterComponent from "./components/Navigation/FooterComponent";

export default {
  name: "App",
  components: {
    Navigation,
    FooterComponent,
  },
  data() {
    return {
      toDepartment: false,
    };
  },
  methods: {
    backToDepartment() {
      this.toDepartment = true;
    },
    backToFirst() {
      this.toDepartment = false;
    },
  },
};
</script>
