<template>
  <div class="row m-0 my-5">
    <div v-if="isMobile" class="mobile d-flex flex-wrap p-0">
      <div class="col-6 p-0">
        <picture>
          <source
            media="(min-width: 768px)"
            sizes="100vw"
            :srcset="
              contentfulComponents.careersHomepageLeftImageDesktop + '?fm=avif'
            "
            type="image/avif"
          />
          <source
            media="(min-width: 768px)"
            sizes="100vw"
            :srcset="contentfulComponents.careersHomepageLeftImageDesktop"
            type="image/jpeg"
          />
          <source
            sizes="100vw"
            :srcset="
              contentfulComponents.careersHomepageLeftImageMobile + '?fm=avif'
            "
            type="image/avif"
          />
          <source
            sizes="100vw"
            :srcset="contentfulComponents.careersHomepageLeftImageMobile"
            type="image/jpeg"
          />
          <img
            :alt="contentfulComponents.careersLeftImageAltText"
            class="w-100 h-100 header-image"
            :src="contentfulComponents.careersHomepageLeftImageMobile"
          />
        </picture>
      </div>
      <div
        class="col-6"
        :style="
          'background-image: url(' +
          contentfulComponents.careersHomepageRightImageMobile +
          '); background-size: cover;'
        "
      ></div>
      <div
        class="header-content__wrapper d-flex flex-wrap align-content-between p-4"
      >
        <div>
          <h1
            class="weight-400 mb-0 header-content__title"
            :style="'color:' + contentfulComponents.careersHomepageTitleColour"
          >
            {{ contentfulComponents.careersHomepageTitle }}
          </h1>
        </div>
        <router-link
          :to="String(contentfulComponents.careersHomepageButtonLink)"
          class="btn rounded-pill py-2 px-3 mt-3 header-content__cta"
          :class="contentfulComponents.careersHomepageButtonColour"
          >{{ contentfulComponents.careersHomepageButtonText }}</router-link
        >
      </div>
    </div>
    <div v-else class="desktop d-flex flex-wrap p-0">
      <div
        class="col-12 col-md-6 p-0 header-content__container"
        :style="
          'background-image: url(' +
          contentfulComponents.careersHomepageRightImageDesktop +
          '); background-size: cover;'
        "
      >
        <div
          class="header-content__wrapper d-flex flex-wrap h-100 align-content-between p-4"
          :style="'background-color: rgba(0, 0, 0, 0.4)'"
        >
          <div>
            <h1
              class="weight-400 mb-0 header-content__title"
              :style="
                'color:' + contentfulComponents.careersHomepageTitleColour
              "
            >
              {{ contentfulComponents.careersHomepageTitle }}
            </h1>
          </div>
          <router-link
            :to="String(contentfulComponents.careersHomepageButtonLink)"
            class="btn rounded-pill py-2 px-3 mt-3 header-content__cta"
            :class="contentfulComponents.careersHomepageButtonColour"
            >{{ contentfulComponents.careersHomepageButtonText }}</router-link
          >
        </div>
      </div>

      <div class="col-6 p-0">
        <picture>
          <source
            media="(min-width: 768px)"
            sizes="100vw"
            :srcset="
              contentfulComponents.careersHomepageLeftImageDesktop + '?fm=avif'
            "
            type="image/avif"
          />
          <source
            media="(min-width: 768px)"
            sizes="100vw"
            :srcset="contentfulComponents.careersHomepageLeftImageDesktop"
            type="image/jpeg"
          />
          <source
            sizes="100vw"
            :srcset="
              contentfulComponents.careersHomepageLeftImageMobile + '?fm=avif'
            "
            type="image/avif"
          />
          <source
            sizes="100vw"
            :srcset="contentfulComponents.careersHomepageLeftImageMobile"
            type="image/jpeg"
          />
          <img
            :alt="contentfulComponents.careersLeftImageAltText"
            class="w-100 h-100 header-image"
            :src="contentfulComponents.careersHomepageLeftImageMobile"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Homepage-Header",
  mixins: [ContentfulCMS],
  props: {
    entryData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isMobile: true,
    };
  },
  methods: {
    watchWindowResize() {
      if (window.outerWidth <= 767) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.getContentful(this.entryData);

    if (window.outerWidth >= 767) {
      this.isMobile = false;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.header-content__container {
  .header-content__wrapper {
    @include media-breakpoint-down(md) {
      background-color: map-get($colors, white) !important;
    }
  }
}

.header-image {
  object-fit: cover;
}

.header-content__title {
  @include media-breakpoint-down(md) {
    max-width: 80%;
    color: map-get($colors, shark) !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 2.8vw;
  }

  @include media-breakpoint-up(lg) {
    max-width: 60%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 50%;
    font-size: 2.5vw;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 2.37rem;
  }
}

.header-content__cta {
  width: 100%;
  font-size: 1rem;
  border: none;
  outline: none;

  @include media-breakpoint-up(sm) {
    width: auto;
  }

  @include media-breakpoint-down(md) {
    background-color: map-get($colors, black) !important;
    color: map-get($colors, white) !important;
  }

  @include media-breakpoint-up(md) {
    &.secondary {
      background-color: map-get($colors, white) !important;
      color: map-get($colors, black) !important;
    }

    &.primary {
      background-color: map-get($colors, black) !important;
      color: map-get($colors, white) !important;
    }
  }
}
</style>
