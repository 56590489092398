<template>
  <div class="regatta-history-wrapper d-flex flex-wrap">
    <section
      class="regatta-history__image-left col-12 col-lg-6 pe-0 pe-lg-4 mb-3 mb-lg-5 order-3 order-lg-1"
    >
      <picture>
        <source
          sizes="100vw"
          :srcset="contentfulComponents.historyImageLeft + '?fm=avif'"
          type="image/avif"
        />
        <img
          :alt="contentfulComponents.historyImageLeftAltText"
          class="w-100"
          :src="contentfulComponents.historyImageLeft"
        />
      </picture>
    </section>
    <section
      class="regatta-history__image-right col-12 col-lg-6 ps-0 ps-lg-4 mb-4 mb-lg-5 order-1 order-lg-2"
    >
      <picture>
        <source
          sizes="100vw"
          :srcset="contentfulComponents.historyImageRight + '?fm=avif'"
          type="image/avif"
        />
        <img
          :alt="contentfulComponents.historyImageRightAltText"
          class="w-100"
          :src="contentfulComponents.historyImageRight"
        />
      </picture>
    </section>
    <section class="regatta-history__content order-2 order-lg-3">
      <div v-for="(element, index) in textContent" :key="index">
        <h2 v-if="element.careersGenericTitle" class="h4 mb-3 mb-lg-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "OurHistory",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  computed: {
    textContent() {
      return this.contentElements(this.contentfulComponents.historyTextContent);
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("1ZBlTWqopzeqOFEPlxvSYn");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.regatta-history__image-left {
  border-right: 0;

  @include media-breakpoint-up(lg) {
    border-right: 1px solid map-get($colors, geyser);
  }

  h2 {
    font-weight: 400;
  }
}
</style>
