<template>
  <div class="apprenticeships-container">
    <section
      class="w-100 apprenticeships-header"
      :style="
        showDesktopImage
          ? 'background: url(' +
            contentfulComponents.apprenticeshipsHeaderImageDesktop +
            ')'
          : 'background: url(' +
            contentfulComponents.apprenticeshipsHeaderImageMobile +
            ')'
      "
    >
      <div
        v-if="contentfulComponents.apprenticeshipsHeaderText"
        class="apprenticeships-header__text-container"
      >
        <template
          v-for="(
            textItem, index
          ) in contentfulComponents.apprenticeshipsHeaderText.split(/\n\n/g)"
        >
          <p :key="index">{{ textItem }}</p>
        </template>
      </div>
    </section>
    <section class="apprenticeships-content d-flex flex-wrap mb-5">
      <div
        class="col-12 col-xxl-6 pt-5 ps-3 pe-3"
        v-for="(cardData, index) in employeeCards"
        :key="index"
        :class="index % 2 === 0 ? 'ps-lg-0 pe-lg-3' : 'ps-lg-3 pe-lg-0'"
      >
        <EmployeeCard :cardData="cardData" />
      </div>
    </section>
    <section class="apprenticeships-video">
      <div style="padding: 36.25% 0 0 0; position: relative">
        <iframe
          :src="
            contentfulComponents.apprenticeshipsVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.apprenticeshipsVideoTitle"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
import EmployeeCard from "../../components/Commons/EmployeeCard";

export default {
  name: "Apply-Here",
  mixins: [ContentfulCMS],
  components: {
    EmployeeCard,
  },
  data() {
    return {
      showDesktopImage: false,
    };
  },
  computed: {
    employeeCards() {
      return this.contentElements(
        this.contentfulComponents.apprenticeshipsEmployeeCards
      );
    },
  },
  methods: {
    watchWindowResize() {
      if (window.outerWidth <= 992) {
        this.showDesktopImage = false;
      } else {
        this.showDesktopImage = true;
      }
    },
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort((a, b) =>
          a.employeeName > b.employeeName
            ? 1
            : b.employeeName > a.employeeName
            ? -1
            : 0
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful("1Zpjf1dk2jmguxYkJQN5wY");
  },
  mounted() {
    if (window.outerWidth >= 992) {
      this.showDesktopImage = true;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.apprenticeships-header {
  background-size: contain !important;
  background-position: top center !important;
  padding-top: 81%;
  background-repeat: no-repeat !important;

  @include media-breakpoint-up(lg) {
    background-size: cover !important;
    padding-block: 22px;
  }

  .apprenticeships-header__text-container {
    position: relative;
    bottom: 1em;
    margin-inline: 1em;
    padding: 2em;
    font-size: 14px;
    background-color: map-get($colors, white);
    box-shadow: 0 0 10px map-get($colors, nobel);

    @include media-breakpoint-up(md) {
      margin-inline: 3em;
    }

    @include media-breakpoint-up(lg) {
      position: static;
      width: 35%;
      margin-inline: 0;
      font-size: 14px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
