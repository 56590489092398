<template>
  <div class="row m-0">
    <div class="col-12 p-0 mb-4">
      <h3 class="text-center mb-0 py-4 border-top border-bottom video-title">
        {{ contentfulComponents.careersVideoHeader }}
      </h3>
    </div>
    <div class="col-12 p-0">
      <div class="position-relative video__container">
        <iframe
          :src="
            contentfulComponents.careersVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.careersVideoTitle"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Homepage-Video",
  mixins: [ContentfulCMS],
  props: {
    entryData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.getContentful(this.entryData);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.video-title {
  font-size: 1.2rem;
}

.video__container {
  padding: 36.25% 0 0 0;

  @include media-breakpoint-down(xl) {
    padding: 45% 0 0 0;
  }

  @include media-breakpoint-down(md) {
    padding: 56.25% 0 0 0;
  }
}
</style>
