<template>
  <div class="container px-3 px-md-5" id="allVacancies">
    <div class="row m-0 mx-auto mb-5 px-0" id="vacanciesRow">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="column left-side">
        <div id="VF_vacancies"></div>

        <div class="accessibility-buttons-wrap">
          <div class="ui container">
            <!-- colour blind feature -->
            <button
              id="colourContrastMode"
              class="toggle"
              @click="colourBlindToggle()"
            >
              Change Colour Contrast
            </button>
          </div>
        </div>
        <!-- End SmartFeed-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";

export default {
  name: "AllVacancies",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
  },
  data() {
    return {};
  },
  methods: {
    colourBlindToggle() {
      // Start of Colour Blind Toggle
      // const toggle = document.querySelector('#colourContrastMode');
      const vf = document.getElementById("VF_vacancies");
      const vfCard = document.getElementById("VF_insert_jobs");
      if (vf.classList.contains("colourBlind")) {
        vf.classList.remove("colourBlind");
        vfCard.classList.remove("colourBlind");
      } else {
        vf.classList.add("colourBlind");
        vfCard.classList.add("colourBlind");
      }
      // End of Colour Blind Toggle
    },
  },
  created() {
    let VFSmartFeed = document.createElement("script");
    VFSmartFeed.setAttribute(
      "src",
      "https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/Regatta_New_Smartfeed/ThreeCardDesign/vf-settings-mockup.js"
    );
    document.head.appendChild(VFSmartFeed);

    let VFMainJS = document.createElement("script");
    VFMainJS.setAttribute(
      "src",
      "https://new-smart-feed.vacancy-filler.co.uk/Main_Project_Files/vf-main.min.js"
    );
    document.head.appendChild(VFMainJS);

    this.initContentful("25EuqdfcgGtbufH7tcT7iO");
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
#allVacancies:deep(#vacanciesRow) {
  * {
    box-sizing: border-box;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  #VF-vacancies-wrapper {
    min-width: unset !important;
  }

  #VF_vacancies {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .left-side {
      width: 100%;
    }
  }

  .job-card-title-container {
    display: flex;
    flex-direction: revert;
    align-items: baseline;
  }

  .job-card-playback {
    cursor: pointer;
  }

  .job-card-playback.active .fa-volume-up:before {
    color: #a59454 !important;
  }

  #VF-vacancies-wrapper #VF_vacancies button.item.active {
    background: #d32919 !important;
  }

  div#VF_status {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    width: 100%;
  }

  #ddlCategory {
    padding: 0.78571429em 2.1em 0.78571429em 1em !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
  }

  .ui.container {
    width: 100%;
    margin: auto;
    padding: 0 !important;
  }

  .job-card-image.company-logo {
    width: 317px;
    /* border: 1px solid #a59454; */
    padding: 3px 3px;
    text-align: center;
  }

  .job-card-image.company-logo img {
    width: auto;
    height: auto;
    max-height: 60px;
    max-width: 100%;
  }

  .VF-job-title {
    color: black !important;
    font-size: 18px !important;
  }

  .job-card-description span {
    line-height: 1.5rem;
  }

  /* Styles related to Contrast Changer*/
  .ui.card,
  .ui.cards > .card {
    background-color: var(--primary-color);
    transition: background-color 0.5s;
  }

  #VF_vacancies.colourBlind {
    --primary-color: #000 !important;
  }

  .togglecolourBlind {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    transition: background-color 0.5s;
  }

  .toggle {
    border-radius: 30px !important;
    background-color: #000000;
    border: none;
    border-radius: 0.3em;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    letter-spacing: 1px;
    width: 140px;
    justify-content: center;
  }

  .toggle:focus {
    outline: none;
  }

  .toggle:active {
    transform: scale(0.98);
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.cards > .card {
      display: flex !important;
      width: 100% !important;
      margin: 1em 0 !important;
    }
  }

  .accessibility-buttons-wrap {
    margin-top: 10px;
  }

  .accessibility-buttons-wrap button.faq-toggle {
    width: auto;
    margin-left: 0px;
  }

  .accessibility-buttons-wrap .buttonText {
    margin-right: 10px;
  }

  .accessibility-buttons-wrap button#colourContrastMode {
    width: auto;
    margin-right: 0px;
  }

  .colourBlind .VF-job-title {
    color: #fff !important;
  }

  .colourBlind .job-card-header button {
    color: #fff;
  }

  .colourBlind .fas {
    color: #fff;
  }

  .colourBlind .job-card-description {
    color: #fff !important;
  }

  .colourBlind .job-card-description-column {
    color: #fff !important;
  }

  .colourBlind .jobCardInfoRow {
    color: #fff !important;
  }

  .colourBlind .jobCardInfoRowCategory {
    color: #fff !important;
  }

  .colourBlind .ui.basic.primary.button {
    background-color: #fff !important;
    color: #000 !important;
  }

  .colourBlind #gdprModalButton {
    color: #fff !important;
    text-decoration: underline;
  }

  /* Styles related to Audio description*/

  button.job-card-playback.active {
    color: #000;
  }

  button.job-card-playback {
    padding: 10px !important;
    background: none;
    border: none !important;
  }

  button.job-card-playback:focus {
    color: #000 !important;
  }

  .fas {
    color: #000;
    background: none;
  }

  .ui.checkbox {
    display: block !important;
  }

  .JAfilterCheckboxes {
    max-height: unset !important;
  }

  #subscribeToJobAlertsSubmit,
  .colourBlind .ui.form .inline.fields .field > label,
  .colourBlind .ui.checkbox label {
    color: white !important;
  }

  .colourBlind #subscribeToJobAlertsSubmit {
    color: #000 !important;
    background-color: white !important;
  }

  .colourBlind #jobAlertsFormInner {
    background-color: #000 !important;
    color: white !important;
  }

  /* Custom Styling*/

  .job-card-description {
    display: flex;
    flex-direction: row;
  }

  .job-card-description-column {
    display: flex;
    flex-direction: column;
  }

  .jobCardInfoRow {
    font-size: 15px !important;
    color: #505050;
    line-height: 1.3em;
  }

  .jobCardInfoRowCategory {
    font-size: 17px !important;
    color: #505050;
    line-height: 1.3em;
  }

  .vl {
    margin-top: 7px;
    border-left: 0.5px solid black;
    height: 30px;
  }

  @media only screen and (max-width: 760px) {
    .job-card-description {
      display: flex;
      flex-direction: column;
    }

    .vl {
      margin-top: 0px;
      border-left: 0px solid black;
      height: 0px;
    }

    #VF_vacancies > div > div.vacancies-nav.ui.item.three.stackable.tabs.menu {
      margin-left: 0px !important;
    }
  }

  .separator {
    display: flex;
    flex-direction: row;
  }

  .job-card-image.company-location {
    width: 317px;
    /* border: 1px solid #a59454; */
    padding: 3px 3px;
    text-align: center;
  }

  .job-card-image.company-location img {
    width: auto;
    height: auto;
    max-height: 160px;
    max-width: 100%;
  }

  @media only screen and (max-width: 1080px) {
    .content.col-1 {
      display: none;
    }

    .sub-col-1 {
      display: none;
    }

    #VF_insert_jobs > div.SimpleCards.card.job-card {
      padding-bottom: 30px !important;
      border-bottom: 2px solid #d8d8d8;
      border-radius: 0px !important;
    }
  }

  @media only screen and (min-width: 1080px) {
    .sub-col-2 {
      display: none;
    }

    .sub-col-3 {
      display: none;
    }

    .ui.card,
    .ui.cards > .card {
      box-shadow: none !important;
      border-bottom: 2px solid #d8d8d8;
      padding-bottom: 40px;
      border-radius: 0px !important;
    }
  }

  .job-card-image.company-images {
    width: auto;
    text-align: center;
  }

  .job-card-image.company-images img {
    width: auto;
    height: auto;
    max-height: 275px;
    max-width: 100%;
  }

  .job-card-image.company-logo-v2 {
    width: 160px;
    padding: 3px 3px;
    text-align: left;
    width: 100%;
  }

  .job-card-image.company-logo-v2 img {
    width: auto;
    height: auto;
    max-width: 100%;
    padding: 15px 0;
    vertical-align: bottom !important;
  }

  /* ui.card {
                      border-top: none !important;
                      border-bottom: none !important;
                      border-left: none !important;
                      border-right: none !important;
                      border: none !important;
                      border-width: 0em !important;
                      border-color: #fff !important;

                    } */

  .ui.card > .content,
  .ui.cards > .card > .content {
    border-top: none !important;
    padding: unset !important;
  }

  .sf-apply-button {
    border-radius: inherit !important;
  }

  .ui.basic.primary.button,
  .ui.basic.primary.buttons .button {
    border-radius: 30px !important;
  }

  #loadM,
  #loadW {
    display: block;
    border-radius: 30px;
    color: white !important;
    background-color: black !important;
    text-transform: inherit;
    width: fit-content;
    margin: auto;
  }

  .ui.card,
  .ui.cards > .card {
    box-shadow: none !important;
  }

  .job-card {
    /* border-bottom: black !important; */
    border-bottom: 2px solid black;
    padding-bottom: 30px;
    border-radius: 0px !important;
  }

  #VF-vacancies-wrapper a {
    font-family: "Poppins", sans-serif;
  }

  /* Job Alerts UI changes */

  .ui.form .required.field > .checkbox:after,
  .ui.form .required.field > label:after,
  .ui.form .required.fields.grouped > label:after,
  .ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
  .ui.form .required.fields:not(.grouped) > .field > label:after {
    content: unset;
  }

  #jobAlertsFormInner {
    border: none;
    box-shadow: none;
  }

  #jobAlertsForm {
    border: none !important;
  }

  .jobAlertsFormContent > .modal-content-title {
    display: flex;
    color: #373737;
  }

  .jobAlertsFormContent > .modal-content-sub-title {
    display: flex;
    color: #373737;
  }

  #jobAlertsEmailLabel,
  #jobAlertsFNameLabel,
  #jobAlertsLNameLabel {
    display: none;
  }

  #jobAlertsFormInner > .inline {
    margin-top: 10px;
  }

  #subscribeToJobAlertsSubmit {
    border-radius: 30px;
    padding-right: 100px !important;
    padding-left: 100px !important;
  }

  #subscribeToJobAlertsSubmit > .checkmark {
    display: none;
  }

  #gdprModalButton {
    color: black !important;
  }

  #VF_vacancies > div > div.vacancies-nav.ui.item.three.stackable.tabs.menu {
    display: flex;
    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    border: none !important;
    box-shadow: none !important;
  }

  .ui.menu {
    margin: 0 0 1rem 0 !important;
  }

  .ui.menu > .item:first-child {
    border-radius: 0;
  }

  #VF_load_careers_vacs,
  #VF_load_register_vacs,
  #VF_load_alerts_vacs_V2 {
    border: 1px solid #bdbdbd;
    height: 40px;
    font-size: small !important;
  }

  #VF_insert_jobs .company-images img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .ui.menu .active.item {
    color: black !important;
    background: white !important;
    border-bottom: 2px solid black !important;
  }

  .ui.button {
    font-family: "Poppins", sans-serif;
  }
}
</style>
