<template>
  <div class="container px-3 px-md-5 mb-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div
        v-if="!imagesData.careersMobileImage_0"
        class="placeholder-container mb-5"
      ></div>
      <div v-else class="our-locations-header-container d-flex mb-5">
        <div class="col-6 col-md-4">
          <picture>
            <source
              :srcset="imagesData.careersMobileImage_0.url"
              media="(max-width: 768px)"
            />
            <img
              :src="imagesData.careersImage_0.url"
              class="w-100"
              :alt="imagesData.careersImage_0.altText"
            />
          </picture>
        </div>
        <div class="d-none d-md-flex col-md-4">
          <img
            :src="imagesData.careersImage_1.url"
            class="w-100"
            :alt="imagesData.careersImage_1.altText"
          />
        </div>
        <div class="col-6 col-md-4">
          <picture>
            <source
              :srcset="imagesData.careersMobileImage_1.url"
              media="(max-width: 768px)"
            />
            <img
              :src="imagesData.careersImage_2.url"
              class="w-100"
              :alt="imagesData.careersImage_2.altText"
            />
          </picture>
        </div>
      </div>
      <h1 class="h3 text-center weight-400">
        {{ contentfulComponents.careersPageTitle }}
      </h1>
      <SubNavigation :subNavId="'JQehjNdnFWRbTQZyb7kq2'" />
      <div class="mt-2 mt-md-4 px-0 px-sm-3 tab-content">
        <div
          class="tab-pane fade show active"
          id="head-office"
          role="tabpanel"
          aria-labelledby="head-office-tab"
        >
          <LocationPage :locationId="'7MWCWf1JmqpMlVCCadC1IV'" />
        </div>
        <div
          class="tab-pane fade"
          id="retail-concessions"
          role="tabpanel"
          aria-labelledby="retail-concession-tab"
        >
          <LocationPage :locationId="'2qzQEJ4R2FK5vIe2AQpHrK'" />
        </div>
        <div
          class="tab-pane fade"
          id="warehouse"
          role="tabpanel"
          aria-labelledby="warehouse-tab"
        >
          <LocationPage :locationId="'PxhcMJEk5vbEFNwCoy63O'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";
import SubNavigation from "../components/Navigation/SubNavigation";
import LocationPage from "../components/Commons/LocationPage";

export default {
  name: "About_Us",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
    SubNavigation,
    LocationPage,
  },
  data() {
    return {
      isShowingTwoImages: false,
      loading: true,
      imagesData: {},
    };
  },
  methods: {
    watchWindowResize() {
      if (window.outerWidth <= 767) {
        this.isShowingTwoImages = false;
      } else {
        this.isShowingTwoImages = true;
      }
    },
    fetchData(data) {
      return setTimeout(async () => {
        let images = {};
        for (const element of data) {
          let imageIndex = element.careersImageIndex;
          let imageAltText = element.careersImageAltText;
          for (const [elementKey, elementValue] of Object.entries(element)) {
            if (
              typeof elementValue === "object" &&
              elementValue !== null &&
              !Array.isArray(elementValue)
            ) {
              const imageUrl = await this.getImageData(elementValue.sys.id);
              const image = {};
              image.url = imageUrl;
              image.altText = imageAltText;
              images[elementKey + "_" + imageIndex] = image;
            }
          }
        }
        this.imagesData = images;
      }, 1000);
    },
  },
  created() {
    this.initContentful("5OcOKX521iWJ1qBRo1P6dy"); // update to the entry ID for that page
    this.dataItem = "ourLocations"; // to use within the init response to get other entries for that page

    if (window.outerWidth >= 767) {
      this.isShowingTwoImages = true;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  async beforeUpdate() {
    if (this.loading) {
      await this.fetchData(this.contentfulComponents.careersPageContent);
    }
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../css/partials/bs-overwrites/colors";

.placeholder-container {
  width: 100%;
  height: 394px;
  margin-inline: 12px;
  background-color: map-get($colors, mercury);
}
</style>
