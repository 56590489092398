<template>
  <div class="brand-wrapper d-flex flex-wrap">
    <section class="brand-image col-12 col-md-6 pe-0 pe-md-3 pe-lg-4 mb-5">
      <picture>
        <source
          :srcset="contentfulComponents.careersBrandPageHeaderImageMobile"
          media="(max-width: 768px)"
        />
        <img
          :src="contentfulComponents.careersBrandPageHeaderImageDesktop"
          class="w-100"
          :alt="contentfulComponents.careersBrandPageHeaderImageAltText"
        />
      </picture>
    </section>

    <section class="brand-content col-12 col-md-6 ps-0 ps-md-3 ps-lg-4">
      <div
        class="mb-4"
        v-for="(element, index) in brandContentElements"
        :key="index"
      >
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>

      <div class="brand-social-links mb-4">
        <ul class="d-flex m-0 list-unstyled">
          <li
            class="me-3"
            v-for="(element, iconIndex) in brandSocialIcons"
            :key="iconIndex"
          >
            <a
              target="_blank"
              rel="nofollow"
              class="social-icon d-block"
              :class="element.careersSocialIconLinkIconClass"
              :href="element.careersSocialIconLinkUrl"
              >{{ element.careersSocialIconLinkCopy }}</a
            >
          </li>
        </ul>
        <a
          :href="String(contentfulComponents.careersBrandPageStoreButtonUrl)"
          class="btn rounded-pill py-2 px-4 mt-4 brand-store__cta"
          :class="contentfulComponents.careersBrandPageStoreButtonColour"
        >
          {{ contentfulComponents.careersBrandPageStoreButtonText }}
        </a>
      </div>

      <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
          :src="
            contentfulComponents.careersBrandPageVideoUrl +
            '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :title="contentfulComponents.careersBrandPageVideoTitle"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Brand-Page",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  props: {
    brandId: {
      type: String,
      required: false,
    },
  },
  computed: {
    brandContentElements() {
      return this.contentElements(
        this.contentfulComponents.careersBrandPageContent
      );
    },
    brandSocialIcons() {
      return this.socialIcons(
        this.contentfulComponents.careersBrandPageSocialLinks
      );
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
    socialIcons(icons) {
      if (icons) {
        let contentData = icons;
        contentData.sort(
          (a, b) =>
            parseFloat(a.careersSocialIconLinkIndex) -
            parseFloat(b.careersSocialIconLinkIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.getContentful(this.brandId);
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.brand-image {
  border-right: 0;

  @include media-breakpoint-up(md) {
    border-right: 1px solid map-get($colors, geyser);
  }
}

.brand-content:deep(p) {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.brand-content {
  h2,
  h3 {
    font-weight: 400;
  }

  .brand-social-links {
    .social-icon {
      width: 25px;
      height: 25px;
      font-size: 0;
      background-size: 99%;
      background-position: center;
      background-repeat: no-repeat;

      &.facebook {
        background-image: url("../../assets/icons/facebook.svg");
      }

      &.instagram {
        background-image: url("../../assets/icons/instagram.svg");
      }

      &.linkedin {
        background-image: url("../../assets/icons/linkedin.svg");
      }

      &.tiktok {
        background-image: url("../../assets/icons/tiktok.svg");
      }

      &.twitter {
        background-image: url("../../assets/icons/twitter.svg");
      }

      &.youtube {
        background-image: url("../../assets/icons/youtube.svg");
      }
    }

    .brand-store__cta {
      &.primary {
        background-color: map-get($colors, black) !important;
        color: map-get($colors, white) !important;

        &:hover {
          background-color: rgba(0, 0, 0, 0.85) !important;
        }
      }

      &.secondary {
        background-color: map-get($colors, white) !important;
        color: map-get($colors, black) !important;

        &:hover {
          background-color: rgba(255, 255, 255, 0.85) !important;
        }
      }
    }
  }
}
</style>
