var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container px-0 mb-5"},[_c('div',{staticClass:"department__container row m-0 my-5"},[_c('div',{staticClass:"department__header-container p-0 mx-auto position-relative",style:(_vm.showDesktopImage
            ? 'background-image: url(' +
              _vm.contentfulComponents.headerImageDesktop +
              ')'
            : 'background-image: url(' +
              _vm.contentfulComponents.headerImageMobile +
              ')')},[_c('button',{staticClass:"position-absolute back-btn",on:{"click":_vm.backToDepartment}},[_vm._v(" Back ")]),_c('div',{staticClass:"department__header-container--content container mx-0 mx-lg-5 px-3 px-md-5 pt-3 pb-5 d-flex flex-column"},[_c('div',{staticClass:"department__header-container--content-container cl-white"},[_c('h1',{staticClass:"department-header"},[_vm._v(" "+_vm._s(_vm.contentfulComponents.header)+" ")])]),_c('div',{staticClass:"pe-3 cl-white weight-300 department__details"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.contentfulComponents.text))])])])]),_c('section',{staticClass:"department-content d-flex flex-wrap mb-5"},_vm._l((_vm.employeeCards),function(cardData,index){return _c('div',{key:index,staticClass:"col-12 col-xxl-6 pt-5 ps-3 pe-3",class:index % 2 === 0 ? 'ps-lg-0 pe-lg-3' : 'ps-lg-3 pe-lg-0'},[_c('EmployeeCard',{attrs:{"cardData":cardData}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }