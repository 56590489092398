<template>
  <div class="container px-3 px-md-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="applicant-privacy-notice-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>

        <div
          class="my-4"
          v-for="(element, index) in applicantPrivacyNoticeContent"
          :key="index"
        >
          <div v-html="element.careersGenericText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";

export default {
  name: "Applicant_Privacy_Notice",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
  },
  computed: {
    applicantPrivacyNoticeContent() {
      return this.contentElements(this.contentfulComponents.careersPageContent);
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.initContentful("cwFLY1dBOueG9mqhb74Mr"); // update to the entry ID for that page
  },
};
</script>
