<template>
  <div class="recruitment-wrapper d-flex flex-wrap">
    <section class="recruitment-images col-12 col-md-6 pe-0 pe-md-3 pe-lg-4">
      <img
        class="recruitment-top-image w-100 mb-3 mb-lg-4"
        :src="contentfulComponents.recruitmentTopImage"
        :alt="contentfulComponents.recruitmentTopImageAltText"
      />

      <img
        class="recruitment-bottom-image d-none d-md-block w-100"
        :src="contentfulComponents.recruitmentBottomImage"
        :alt="contentfulComponents.recruitmentBottomImageAltText"
      />
    </section>

    <section class="recruitment-content col-12 col-md-6 ps-0 ps-md-3 ps-lg-4">
      <div
        class="mb-4"
        v-for="(element, index) in contentElements"
        :key="index"
      >
        <h2 v-if="element.careersGenericTitle" class="h4 mb-4">
          {{ element.careersGenericTitle }}
        </h2>
        <div v-html="element.careersGenericText"></div>
      </div>
      <img
        class="recruitment-bottom-image d-block d-md-none w-100"
        :src="contentfulComponents.recruitmentBottomImage"
        :alt="contentfulComponents.recruitmentBottomImageAltText"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Our-Recruitment",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {};
  },
  computed: {
    contentElements() {
      if (this.contentfulComponents.recruitmentContent) {
        let contentData = this.contentfulComponents.recruitmentContent;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  methods: {},
  created() {
    this.getContentful("2TzmjBq0e7a3F6cPveaE0s");
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.recruitment-images {
  border-right: 0;

  @include media-breakpoint-up(md) {
    border-right: 1px solid map-get($colors, geyser);
  }
}

.recruitment-content {
  h2 {
    font-weight: 400;
  }
}
</style>
