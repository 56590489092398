<template>
  <div class="container px-3 px-md-5">
    <div class="row m-0 mx-auto">
      <BreadcrumbsNavigation
        :breadcrumbs="contentfulComponents.careersBreadcrumbs"
      />
      <div class="cookie-policy-container">
        <h1 class="h3 text-center weight-400">
          {{ contentfulComponents.careersPageTitle }}
        </h1>

        <div
          class="my-4"
          v-for="(element, index) in cookiePolicyContent"
          :key="index"
        >
          <div v-html="element.careersGenericText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../mixin/ContentfulCMS";
import BreadcrumbsNavigation from "../components/Navigation/BreadcrumbsNavigation";

export default {
  name: "Cookie-Policy",
  mixins: [ContentfulCMS],
  components: {
    BreadcrumbsNavigation,
  },
  computed: {
    cookiePolicyContent() {
      return this.contentElements(this.contentfulComponents.careersPageContent);
    },
  },
  methods: {
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort(
          (a, b) =>
            parseFloat(a.genericTextItemIndex) -
            parseFloat(b.genericTextItemIndex)
        );
        return contentData;
      }
      return null;
    },
  },
  created() {
    this.initContentful("3wDzoR101j88KCvnYSzB4l"); // update to the entry ID for that page
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../css/partials/bs-overwrites/colors";

.cookie-policy-container:deep(.table) {
  font-size: 14px;

  tbody {
    tr {
      th {
        background-color: map-get($colors, alto);
      }

      &:nth-child(odd) {
        background-color: map-get($colors, gallery);
      }
    }
  }
}

.cookie-policy-container:deep(a) {
  word-break: break-word;
}
</style>
