<template>
  <div v-if="benefitsData" class="benefits_container col-12 p-0">
    <div v-if="!loading">
      <WarehouseBenefits
        v-if="contentId == 'head-office'"
        :benefitsData="benefitsData"
        :benefitsImages="benefitsImages"
      />
      <WarehouseBenefits
        v-if="contentId == 'retail-concessions'"
        :benefitsData="benefitsData"
        :benefitsImages="benefitsImages"
      />
      <WarehouseBenefits
        v-if="contentId == 'warehouse'"
        :benefitsData="benefitsData"
        :benefitsImages="benefitsImages"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";
/* import HeadOfficeBenefits from "../WhereWeAre/HeadOfficeBenefits"; */
/* import RetailBenefits from "../WhereWeAre/RetailBenefits"; */
import WarehouseBenefits from "../WhereWeAre/WarehouseBenefits";

export default {
  name: "Benefits-Component",
  mixins: [ContentfulCMS],
  components: {
    /* HeadOfficeBenefits, */
    /* RetailBenefits, */
    WarehouseBenefits,
  },
  props: {
    benefitsData: {
      type: Array,
      required: true,
    },
    contentId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      benefitsImages: {},
    };
  },
  computed: {},
  methods: {
    async fetchData(data) {
      let images = {};
      for (const element of data) {
        for (const [elementKey, elementValue] of Object.entries(element)) {
          if (
            typeof elementValue === "object" &&
            elementValue !== null &&
            !Array.isArray(elementValue)
          ) {
            console.log(elementKey);
            const imageUrl = await this.getImageData(elementValue.sys.id);
            images[element.id] = imageUrl;
          }
        }
      }
      this.benefitsImages = images;
    },
  },
  async beforeUpdate() {
    if (this.loading) {
      await this.fetchData(this.benefitsData);
    }
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";
</style>
