<template>
  <div
    v-if="cardData"
    class="card_container d-flex flex-wrap p-3 border border-dark h-100"
  >
    <img
      v-if="!loading"
      class="col-12 col-md-5 col-lg-4 me-0 me-md-3"
      :src="contentfulComponents.employeeImage"
      :alt="cardData.name"
    />
    <div
      class="d-flex flex-column justify-content-between col me-0 me-md-3 ps-0 ps-md-3"
    >
      <div>
        <h3 v-if="cardData.role" class="d-none d-md-block h5 mb-3">
          {{ cardData.name + " - " + cardData.role }}
        </h3>
        <h3 v-else class="d-none d-md-block h5 mb-3">{{ cardData.name }}</h3>
        <h3 class="d-block d-md-none mb-0 pt-3">{{ cardData.name }}</h3>
        <h3 v-if="cardData.role" class="d-block d-md-none h4 mb-3">
          {{ cardData.role }}
        </h3>
        <div v-if="cardData.buttonLink && cardData.bodyText">
          <template
            v-for="(textItem, index) in trimmedText
              .substring(
                0,
                Math.min(trimmedText.length, trimmedText.lastIndexOf(' '))
              )
              .concat('...')
              .split(/\n\n/g)"
          >
            <p :key="index">{{ textItem }}</p>
          </template>
        </div>
        <div v-else-if="cardData.bodyText" v-html="cardData.bodyText"></div>
      </div>
      <div class="align-self-end">
        <router-link
          v-if="cardData.buttonLink"
          :to="String(cardData.buttonLink)"
          class="btn rounded-pill py-2 px-5"
          :class="cardData.buttonClass"
        >
          {{ cardData.buttonText }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Employee-Card",
  mixins: [ContentfulCMS],
  props: {
    cardData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    trimmedText() {
      return this.cardData.bodyText.substring(0, 200);
    },
  },
  methods: {
    async fetchImage() {
      await this.getContentfulObject(
        "employeeImage",
        this.cardData.mobileImage.sys.id
      );
      this.loading = false;
    },
  },
  created() {},
  mounted() {
    this.fetchImage();
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.card_container {
  img {
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down(md) {
      aspect-ratio: 1;
    }
  }

  .secondary {
    background-color: map-get($colors, white) !important;
    color: map-get($colors, black) !important;
  }

  .primary {
    background-color: map-get($colors, black) !important;
    color: map-get($colors, white) !important;
  }
}
</style>
