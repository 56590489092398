<template>
  <div class="container px-0 mb-5">
    <div class="employee__container row m-0 my-5">
      <div
        class="employee__header-container p-0 mx-auto position-relative"
        :style="
          showDesktopImage
            ? 'background-image: url(' + contentfulComponents.desktopImage + ')'
            : 'background-image: url(' + contentfulComponents.mobileImage + ')'
        "
      >
        <button @click="backToDepartment" class="position-absolute back-btn">
          Back
        </button>
        <div
          class="employee__header-container--content container mx-0 mx-lg-5 px-3 px-md-5 pt-3 pb-5 d-flex flex-column"
        >
          <div class="employee__header-container--content-container cl-white">
            <p class="mb-0 weight-200">Meet</p>
            <h1 class="employee-header">
              {{ contentfulComponents.heading }}
            </h1>
            <p class="employee__details pe-3 m-0 cl-white weight-200">
              {{ contentfulComponents.subheading }}
            </p>
          </div>
        </div>
      </div>
      <section class="employee-content d-flex flex-wrap my-5">
        <div
          v-if="contentfulComponents.bodyText"
          class="col-12"
          v-html="contentfulComponents.bodyText"
        ></div>
        <div v-if="contentfulComponents.videoUrl" class="col-12 p-0">
          <div class="position-relative video__container">
            <iframe
              :src="
                contentfulComponents.videoUrl +
                '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              class="video__container__content"
              :title="contentfulComponents.heading"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "../../mixin/ContentfulCMS";

export default {
  name: "EmployeeProfile",
  mixins: [ContentfulCMS],
  components: {},
  data() {
    return {
      contentfulIDs: [
        {
          path: "/buying-and-product/chelsea-norcross/",
          id: "3ONb5YtmbxerQp47XviAro",
        },
        {
          path: "/buying-and-product/laura-buschini/",
          id: "7p05AhjEliEfXM4nIeM4Fo",
        },
        { path: "/buying-and-product/vic-pugh/", id: "3gmMchZWQbpQX201IscBOe" },
        { path: "/contact-centre/becca-bolton/", id: "aSf5PPOU0QQtt0RdWBqIs" },
        {
          path: "/contact-centre/chloe-siddeley/",
          id: "6DUt1wzySJdow6yTxm3flR",
        },
        {
          path: "/wholesale-customer-service/karen-howarth/",
          id: "3krR6O3LdCvmvS23MPPWr",
        },
        {
          path: "/wholesale-customer-service/sam-mcgivern/",
          id: "4WkEr5Yix3mUEIH5UR90Ey",
        },
        { path: "/design/beth-warburton/", id: "7c84kgA6SzmGFgw3UEwLPc" },
        { path: "/design/hannah-bullock/", id: "1ytZTOyS4HzWS3xerx2jTv" },
        { path: "/design/jo-morfoot/", id: "4X74mJ7HUaZiScVb3hwqyO" },
        {
          path: "/people-team/charlie-berrisford/",
          id: "3G0rh4SIiyWowYRWTm6HhU",
        },
        { path: "/people-team/caron-wright/", id: "2AXdq4IwrWoKumfjuhqpmZ" },
        { path: "/imports/sam-mcdonald/", id: "2T4NrBmCEc60Z7s43qoE9F" },
        { path: "/imports/gemma-marsh/", id: "1myuX2mYWLT36LjEsHhKe1" },
        { path: "/it/anil-sunil-malkit/", id: "2WWtng1ADTHHAkbwfa5jZd" },
        { path: "/health-and-safety/bob-reid/", id: "7z6xSLG28ZDuYDfQSq4xDG" },
        { path: "/digital/lena-mistry/", id: "6BkJBXKI6V63VqX6SNQSuk" },
        { path: "/digital/emily-goddard/", id: "5eRq12X66U800R41hdeT0t" },
        {
          path: "/finance/wani-syazwani-rohaizat/",
          id: "7u4Coq6JFuhBmIFuxUtHBJ",
        },
        { path: "/finance/joe-regan/", id: "5JStCXc9KPuU1kHnUqXmgp" },
        { path: "/merchandising/jay-thaker/", id: "59FLTKmzmHOI036MoOeZ60" },
        { path: "/merchandising/lynsey-morton/", id: "1jzKN8pTSwe29bpH9Hlxln" },
        { path: "/marketing/sally-bailey/", id: "2KTCY6pkEs2ngVAfhpEH2Z" },
        { path: "/marketing/cat-mather/", id: "MlUvHwO9aMVknR8tNOM57" },
        { path: "/marketing/david-sewell/", id: "4C21D51BTGnd0T9ZUSxc47" },
        {
          path: "/procurement-and-legal/ketsia-leury-agarat/",
          id: "59uJ0p1sOhXCsIS5VdS9o1",
        },
        {
          path: "/procurement-and-legal/rachel-thomas/",
          id: "7aGoWWHQ1aVCOYE83hLih9",
        },
        {
          path: "/retail-and-concessions/kassie-eccles/",
          id: "2EDMFv8RFym4Kerew2KTgo",
        },
        { path: "/sales/steve-lloyd/", id: "2N53JRITl34cVBft5JfHLu" },
        { path: "/sales/mimi-ndaya/", id: "6KzQVP21CpG50JwXb1PFXy" },
        { path: "/sales/simon-tai-yip/", id: "4hRU4WUND7Eovy2O5CJdlL" },
        { path: "/warehouse/kasia-zareba/", id: "4glWu3INt2Js7VnFuEmzJR" },
        { path: "/warehouse/adam-bishop/", id: "5pm1HZ6ZmJWCelFaUsbjV7" },
      ],
      showDesktopImage: false,
    };
  },
  computed: {
    currentPath() {
      return this.contentfulIDs.filter((id) => id.path == this.$route.path);
    },
  },
  methods: {
    watchWindowResize() {
      if (window.outerWidth <= 992) {
        this.showDesktopImage = false;
      } else {
        this.showDesktopImage = true;
      }
    },
    contentElements(elements) {
      if (elements) {
        let contentData = elements;
        contentData.sort((a, b) =>
          a.employeeName > b.employeeName
            ? 1
            : b.employeeName > a.employeeName
            ? -1
            : 0
        );
        return contentData;
      }
      return null;
    },
    backToDepartment() {
      this.$router.go(-1);
    },
  },
  created() {
    this.initContentful(this.currentPath[0].id); // update to the entry ID for that page
  },
  mounted() {
    if (window.outerWidth >= 992) {
      this.showDesktopImage = true;
    }
    window.addEventListener("resize", this.watchWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.watchWindowResize);
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.employee__container {
  @include media-breakpoint-down(lg) {
    padding-top: 40px;
  }

  .employee__header-container {
    display: flex;
    height: 500px;
    background-position: center top;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);

    .back-btn {
      position: relative;
      background: transparent;
      border: none;
      color: map-get($colors, black);
      margin-left: 50px;
      margin-top: -40px;

      &:before {
        content: "<";
        display: inline-block;
        width: 30px;
        height: 30px;
        left: -32px;
        position: absolute;
        border: 1px solid map-get($colors, black);
        border-radius: 50%;
        margin-top: -4px;
        padding-top: 3px;
      }

      @include media-breakpoint-up(lg) {
        color: white;
        margin-top: 30px;
        margin-left: 128px;

        &:before {
          border: 1px solid map-get($colors, white);
        }
      }
    }

    .employee__header-container--content {
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        justify-content: center;
      }

      .employee__header-container--content-container {
        margin-top: 10rem;

        .employee-header {
          font-size: calc(1.8rem + 1.5vw);
          font-weight: 200 !important;

          @include media-breakpoint-up(xl) {
            font-size: 3.5rem;
          }
        }

        p {
          font-size: calc(0.8rem + 1.5vw);

          @include media-breakpoint-up(md) {
            font-size: calc(0.5rem + 1.5vw);
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .employee-content {
    @media only screen and (min-width: 1801px) {
      padding-left: 0;
      padding-right: 0;
    }

    &:deep(strong) {
      font-weight: 600;
    }

    .video__container {
      padding: 36.25% 0 0 0;

      @include media-breakpoint-down(xl) {
        padding: 45% 0 0 0;
      }

      @include media-breakpoint-down(md) {
        padding: 56.25% 0 0 0;
      }

      &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
