<template>
  <div
    class="w-100 apply-here__container"
    :style="
      'background-image: url(' + contentfulComponents.applyHereHeaderImage + ')'
    "
  >
    <div
      class="d-flex justify-content-center align-items-center w-100 h-100 overlay"
    >
      <router-link
        :to="String(contentfulComponents.applyHereButtonUrl)"
        class="btn rounded-pill py-2 px-5"
        :class="contentfulComponents.applyHereButtonClass"
      >
        {{ contentfulComponents.applyHereButtonText }}
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentfulCMS from "@/mixin/ContentfulCMS";

export default {
  name: "Apply-Here",
  mixins: [ContentfulCMS],
  data() {
    return {};
  },
  methods: {},
  created() {
    this.getContentful("1xJgDH4fEEKBuEzBXw1xeW");
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.apply-here__container {
  height: 400px;
  background-size: cover;
  background-position: center;

  .overlay {
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
}

.secondary {
  background-color: map-get($colors, white) !important;
  color: map-get($colors, black) !important;
}

.primary {
  background-color: map-get($colors, black) !important;
  color: map-get($colors, white) !important;
}
</style>
