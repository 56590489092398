<template>
  <nav style="--bs-breadcrumb-divider: '|'" aria-label="breadcrumb">
    <ol class="breadcrumb m-0 px-0 px-sm-3 pt-3 pb-5">
      <li
        v-for="(item, breadcrumbIndex) in breadcrumbElements"
        :key="breadcrumbIndex"
        class="breadcrumb-item"
        :class="{ active: breadcrumbIndex == breadcrumbElements.length - 1 }"
        :aria-current="
          breadcrumbIndex == breadcrumbElements.length - 1 ? 'page' : false
        "
      >
        <template v-if="breadcrumbIndex == breadcrumbElements.length - 1">
          {{ item.breadcrumbText }}
        </template>
        <router-link v-else :to="item.breadcrumbUrl">{{
          item.breadcrumbText
        }}</router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs-Navigation",
  data() {
    return {};
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: false,
    },
  },
  computed: {
    breadcrumbElements() {
      if (this.breadcrumbs) {
        let breadcrumbData = this.breadcrumbs;
        breadcrumbData.sort(
          (a, b) =>
            parseFloat(a.breadcrumbIndex) - parseFloat(b.breadcrumbIndex)
        );
        return breadcrumbData;
      }
      return null;
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "../../css/partials/bs-overwrites/colors";

.breadcrumb-item {
  :not(.active) {
    color: map-get($colors, black);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: map-get($colors, shark);
    }
  }
}
</style>
